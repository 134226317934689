define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      /* this._super(...arguments); */
      this._super(controller);

      var periodo_k = localStorage.getItem("periodo_k"); //window.location.href.substring( window.location.href.lastIndexOf( '/' ) + 1 );

      controller.set('periodo_k', periodo_k);
      controller.set('isVisibleGuia2', false);
      controller.set('isVisibleGuia3', false);
      setTimeout(function () {
        $(".elemento").click(function () {
          $(".arrowRight").addClass("d-none");
          $(".elemento").removeClass("active");
          $(".elemento").parent().removeClass("text-right");
          $(this).addClass("active");
          $(this).parent().addClass("text-right");
          $(this).find(".arrowRight").removeClass("d-none");
          $("#card-header-open").text($(this).text());
        });
        $("#opcion-evidencias").change(function () {
          window.location.href = $(this).find("option:selected").data("url");
        });

        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          //if(1){  
          $("#contenedorEvidencia").removeAttr("style");
        }
      }, 1000);
      this.guias_asignadas();
    },
    guias_asignadas: function guias_asignadas(controller) {
      var periodo_k = localStorage.getItem("periodo_k");
      var self = this;
      $.ajax({
        url: constant.APIURL + '/nom035stps/evidenciasats/obtener_guias_por_periodo',
        type: 'POST',
        sync: true,
        xhrFields: {
          withCredentials: true
        },
        data: 'periodo_k=' + periodo_k,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var responseObject = JSON.parse(response_string);
          responseObject.data.forEach(function (data) {
            if (data.guia_asignada == 'GUIA 2') {
              self.controller.set('isVisibleGuia2', true);
            }

            if (data.guia_asignada == 'GUIA 3') {
              self.controller.set('isVisibleGuia3', true);
            }
          });
        },
        error: function error(_error) {
          console.log(_error);
        }
      });
    }
  });

  _exports.default = _default;
});