define("comunidades-nafin/components/competencias/evaluacion-usuario/aplicacion/evaluaciones-carrousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listaEvaluaciones: [],
    listaEvaluacionesEmpresa: [],
    isCardMonitoreoVisible: false,
    msjMonitoreo: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('listaEvaluaciones', this.listaEvaluaciones);
      this.set('listaEvaluacionesEmpresa', this.listaEvaluacionesEmpresa);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      this.isCardMonitoreoVisible = self.session.userData.rol_k == '9' ? true : false;
      this.set('isCardMonitoreoVisible', this.isCardMonitoreoVisible);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadEvaluaciones();
    },
    loadEvaluaciones: function loadEvaluaciones() {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/listarevaluacionesdisponiblesporevaluador',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          console.log(response);
          self.set('listaEvaluaciones', response.data);
          setTimeout(function () {
            if ($(".evaluacion_pendiente").length == 0) $("#texto_auxiliar .alert-success").text("Sin evaluaciones pendientes"); //$(".titulo_evaluaciones_pendientes").hide();

            if ($(".evaluacion_anterior").length == 0) {
              $(".titulo_evaluaciones_anteriores").hide();
              $("#texto_auxiliar").hide();
            }
          }, 2000);
        },
        error: function error(e) {
          console.log(e);
        }
      }); // Se cargan evaluaciones por empresa para que el administrador pueda ver los resultados

      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/listarevaluacionesdisponiblesporempresa',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          console.log(response);
          self.set('listaEvaluacionesEmpresa', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      }); // Cargamos la lista de los colaboradores

      var evaluacion_k = localStorage.getItem("evaluacion_k");
      self.set('msjMonitoreo', this.msjMonitoreo);
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/detallesEvaluacion',
        type: "post",
        data: {
          evaluacion_k: evaluacion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var usuario_k = response.data.usuario.usuario_k;
          var colaboradores = response.data.colaboradores["" + usuario_k + ""].colaboradores;

          for (var key in colaboradores) {
            if (colaboradores.hasOwnProperty(key)) {
              var val = colaboradores[key].detalles.resultado_obtenido;
              console.log('key:', key);
              console.log('val:', val);

              if (val == '0.00' && key != usuario_k) {
                this.msjMonitoreo = 'Ninguno de tu colaboradores ha terminado.';
                self.set('msjMonitoreo', this.msjMonitoreo);
              }
            }
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      irAResultados: function irAResultados() {
        var evaluacion_k = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        localStorage.setItem("evaluacion_k", evaluacion_k);
        document.location.href = "/#/competencias/evaluacion-usuario/resultados";
      }
    }
  });

  _exports.default = _default;
});