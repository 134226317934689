define("comunidades-nafin/templates/components/human-talent-components/inputs/form-group/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fTnW9ffi",
    "block": "{\"symbols\":[\"recordData\",\"&default\"],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"form-group row\"],[8],[0,\"\\n        \"],[6,\"label\"],[10,\"for\",\"colFormLabel\"],[10,\"class\",\"col-2 col-form-label\"],[8],[1,[20,\"label\"],false],[9],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-10\"],[8],[0,\"\\n            \"],[6,\"select\"],[11,\"id\",[27,[[20,\"guid\"]]]],[10,\"class\",\"custom-select\"],[11,\"disabled\",[20,\"isWorking\"]],[8],[0,\"\\n                \"],[6,\"option\"],[10,\"selected\",\"\"],[10,\"value\",\"null\"],[8],[1,[20,\"placeholder\"],false],[9],[0,\"\\n\"],[4,\"each\",[[22,[\"data\"]]],null,{\"statements\":[[0,\"                        \"],[6,\"option\"],[11,\"value\",[21,1,[\"value\"]]],[8],[1,[21,1,[\"text\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[13,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/human-talent-components/inputs/form-group/select.hbs"
    }
  });

  _exports.default = _default;
});