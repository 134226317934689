define("comunidades-nafin/routes/nom-035-stps/reportes/administrador/entorno-organizacional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      setTimeout(function () {
        $("#reporte-guia-3-datastudio").attr("src", window.localStorage.getItem("reporte-guia-3-datastudio"));
      }, 1000);
      setTimeout(function () {
        $(".card-header").removeClass("alert-info");
        $("#entornoCard").addClass("alert-info");
      }, 500);
    }
  });

  _exports.default = _default;
});