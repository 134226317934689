define("comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/categoria-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vdC+R2RS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[6,\"div\"],[11,\"class\",[27,[\"card text-center m-3 \",[26,\"if\",[[22,[\"categoriaRecord\",\"seleccionado\"]],\"bg-card-competencias-active\",\"bg-card-competencias\"],null],\" rounded\"]]],[10,\"style\",\"cursor: pointer; width: 16rem;\"],[3,\"action\",[[21,0,[]],\"onClick\"]],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"card-body\"],[8],[0,\"\\n        \"],[6,\"p\"],[10,\"class\",\"card-text\"],[8],[1,[22,[\"categoriaRecord\",\"nombre\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,1],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/categoria-card.hbs"
    }
  });

  _exports.default = _default;
});