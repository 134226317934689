define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/capacitaciones-certificados", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KagluzbH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"alert alert-info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n            \"],[1,[26,\"translate\",[\"nom_035_stps.carpeta_evidencias.msg.msg_descripcion_capacitaciones_certificados.valor\",\"En esta sección podrá exportar las certificaciones de capacitación de los usuarios.\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion mt-5\"],[8],[0,\"\\n    \"],[1,[26,\"nom-035-stps/reportes/reports/ats-generacion-masiva-capacitaciones-certificados-de-usuarios\",null,[[\"onShowFormRequestGenerateZip\",\"onBindScopeToParent\",\"periodo_k\"],[[26,\"route-action\",[\"showFormRequestGenerateZip\"],null],[26,\"route-action\",[\"bindScopeToReportAtsGeneracionMasivaReportesComponent\"],null],[22,[\"periodo_k\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"nom-035-stps/acontecimientos-traumaticos-severos/request-form-massive-capacitaciones-certificados-de-usuarios\",null,[[\"updateStore\",\"isVisibleCreateUpdateForm\",\"command\",\"isCommandUpdate\",\"onAfterCreate\",\"onAfterUpdate\",\"periodo_k\"],[[22,[\"updateStore\"]],[22,[\"isVisibleCreateUpdateForm\"]],[22,[\"createDeleteCommand\"]],[22,[\"isCommandUpdate\"]],[26,\"route-action\",[\"afterCreate\"],null],[26,\"route-action\",[\"afterUpdate\"],null],[22,[\"periodo_k\"]]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/capacitaciones-certificados.hbs"
    }
  });

  _exports.default = _default;
});