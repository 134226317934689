define("comunidades-nafin/routes/noticias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var noMayorTreinta1;
  var noMayorTreinta2;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    sortJSON: function sortJSON(data, key, orden) {
      return data.sort(function (a, b) {
        return parseInt(a.key) - parseInt(b.key);
      });
    },
    ordBurbuja: function ordBurbuja(miArray, key) {
      var k;

      for (var i = 1; i < miArray.length; i++) {
        for (var j = 0; j < miArray.length - i; j++) {
          if (parseInt(miArray[j][key]) < parseInt(miArray[j + 1][key])) {
            k = miArray[j + 1];
            miArray[j + 1] = miArray[j];
            miArray[j] = k;
          }
        }
      }

      return miArray;
    },
    setupController: function setupController(controller) {
      var self = this;
      var idioma = {};

      if (window.idioma) {
        idioma = window.idioma;
      }

      controller.set("idioma", idioma);
      Ember.$.ajax({
        url: constant.APIURL + 'noticia/listarNoticiasRelevantes',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);
          var firstNotices = [];
          var arrNoticiasDestacadas = [];
          var dia, mes, mesNumero, año, fecha;
          firstNotices.push(respons.data);
          firstNotices = self.ordBurbuja(firstNotices[0], 'numero_visualizaciones');

          for (var i = 0; i < 10; i++) {
            arrNoticiasDestacadas.push(firstNotices[i]);
            dia = arrNoticiasDestacadas[i].timestamp_creacion.substring(8, 10);
            mesNumero = parseInt(arrNoticiasDestacadas[i].timestamp_creacion.substring(5, 7));

            switch (mesNumero) {
              case 1:
                mes = 'enero';
                break;

              case 2:
                mes = 'febrero';
                break;

              case 3:
                mes = 'marzo';
                break;

              case 4:
                mes = 'abril';
                break;

              case 5:
                mes = 'mayo';
                break;

              case 6:
                mes = 'junio';
                break;

              case 7:
                mes = 'julio';
                break;

              case 8:
                mes = 'agosto';
                break;

              case 9:
                mes = 'septiembre';
                break;

              case 10:
                mes = 'octubre';
                break;

              case 11:
                mes = 'noviembre';
                break;

              case 12:
                mes = 'diciembre';
                break;

              default:
                break;
            }

            var diaModificacion = arrNoticiasDestacadas[i].timestamp_modificacion.substring(8, 10);
            var mesNumeroModificacion = parseInt(arrNoticiasDestacadas[i].timestamp_modificacion.substring(5, 7));
            var mesModificacion = "";

            switch (mesNumeroModificacion) {
              case 1:
                mesModificacion = 'enero';
                break;

              case 2:
                mesModificacion = 'febrero';
                break;

              case 3:
                mesModificacion = 'marzo';
                break;

              case 4:
                mesModificacion = 'abril';
                break;

              case 5:
                mesModificacion = 'mayo';
                break;

              case 6:
                mesModificacion = 'junio';
                break;

              case 7:
                mesModificacion = 'julio';
                break;

              case 8:
                mesModificacion = 'agosto';
                break;

              case 9:
                mesModificacion = 'septiembre';
                break;

              case 10:
                mesModificacion = 'octubre';
                break;

              case 11:
                mesModificacion = 'noviembre';
                break;

              case 12:
                mesModificacion = 'diciembre';
                break;

              default:
                break;
            }

            var anio = arrNoticiasDestacadas[i].timestamp_creacion.substring(0, 4);

            var _fecha = dia + ' de ' + mes + ' de ' + anio;

            mesModificacion = window.translate('general.mes.lbl.lbl_mes_' + mesModificacion + ".valor", mesModificacion);
            arrNoticiasDestacadas[i].timestamp_creacion = window.dateFormat(dia, mes, anio);
            var anio_modificacion = arrNoticiasDestacadas[i].timestamp_modificacion.substring(0, 4);
            var fecha_modificacion = diaModificacion + ' de ' + mesModificacion + ' de ' + anio_modificacion;
            arrNoticiasDestacadas[i].timestamp_modificacion = window.dateFormat(diaModificacion, mesModificacion, anio_modificacion);
            arrNoticiasDestacadas[i].visible = true;
            var noMayorTreintaActual = new Date();
            noMayorTreinta2 = new Date(año + '-' + mesNumero + '-' + dia);

            if (noMayorTreintaActual.setDate(noMayorTreinta2.getDate() - 30) != noMayorTreinta2.getDate()) {
              arrNoticiasDestacadas[i].visible = false;
            }
          }

          console.log(arrNoticiasDestacadas);
          controller.set('noticias-relevantes', arrNoticiasDestacadas);
        },
        error: function error(e) {
          console.log(e);
        }
      });
      var params = window.location.href.split("/");
      params = new URLSearchParams(params[params.length - 1]);
      var noticias_k = params.get('noticia_k');
      var tags = params.get('tags');

      if (noticias_k == null || noticias_k == '' || noticias_k == undefined) {
        var datos = '';

        if (tags != null && tags != '' && tags != undefined) {
          datos = 'tags=' + tags;
          controller.set("inputTags", tags);
        }

        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listarNoticiasPropietarioComunidades',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          //data: 'params=%7B%22comunidad_k%22%3A-1%2C%22tipo%22%3A1%7D',
          data: datos,
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            var respons = JSON.parse(r);
            var firstNotices = [];
            var dia, mes, mesNumero, año, fecha; //console.log('respons.success.data');
            //console.log(respons.success.data);

            for (var i = 0; i < respons.success.data.length; i++) {
              Ember.$.ajax({
                url: constant.APIURL + 'usuario/datospersonales/',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: {
                  withCredentials: true
                },
                success: function success(r) {
                  var data = JSON.parse(r);

                  if (data.configuracion.general_compatir_noticias_en_facebook_habilitar == false) {
                    Ember.$("#face_vis").remove();
                  } else {
                    Ember.$("#face_vis").show();
                  }

                  if (data.configuracion.general_compatir_noticias_en_twitter_habilitar == false) {
                    Ember.$("#twitter_vis").remove();
                  } else {
                    Ember.$("#twitter_vis").show();
                  }

                  if (data.configuracion.general_compatir_noticias_en_linkedin_habilitar == false) {
                    Ember.$("#link_vis").remove();
                  } else {
                    Ember.$("#link_vis").show();
                  }
                },
                error: function error(e) {
                  console.log(e);
                }
              }); //Código para agregar campos al json y poder anexar un nombre único al control upload con el id de la noticia y la imagen se asigne correctamente.

              respons.success.data[i].ctrl_upload_photo = "photo-editar-noticia-" + respons.success.data[i].noticia_k;
              respons.success.data[i].ctrl_for_upload_photo = "upload-photo-editar-noticia-" + respons.success.data[i].noticia_k; //Fix target blank

              if (respons.success.data[i].cuerpo.indexOf('<div>') != 0) {
                respons.success.data[i].cuerpo = '<div>' + respons.success.data[i].cuerpo + '</div>';
              }

              var detalle = Ember.$.parseHTML(respons.success.data[i].cuerpo, document, true);

              if (Ember.$(detalle).find('a').length) {
                Ember.$(detalle).find('a').attr('target', '_blank');
                var url = Ember.$(detalle).find('a').attr('href');

                if (url && url.indexOf('http') != 0) {
                  Ember.$(detalle).find('a').attr('href', '//' + url);
                }

                respons.success.data[i].cuerpo = Ember.$(detalle).html();
              }

              firstNotices.push(respons.success.data[i]);
              dia = firstNotices[i].timestamp_creacion.substring(8, 10);
              mesNumero = parseInt(firstNotices[i].timestamp_creacion.substring(5, 7));

              switch (mesNumero) {
                case 1:
                  mes = 'enero';
                  break;

                case 2:
                  mes = 'febrero';
                  break;

                case 3:
                  mes = 'marzo';
                  break;

                case 4:
                  mes = 'abril';
                  break;

                case 5:
                  mes = 'mayo';
                  break;

                case 6:
                  mes = 'junio';
                  break;

                case 7:
                  mes = 'julio';
                  break;

                case 8:
                  mes = 'agosto';
                  break;

                case 9:
                  mes = 'septiembre';
                  break;

                case 10:
                  mes = 'octubre';
                  break;

                case 11:
                  mes = 'noviembre';
                  break;

                case 12:
                  mes = 'diciembre';
                  break;

                default:
                  break;
              }

              var anio = firstNotices[i].timestamp_creacion.substring(0, 4);
              mes = window.translate('general.mes.lbl.lbl_mes_' + mes + ".valor", mes);
              fecha = window.dateFormat(dia, mes, anio);
              firstNotices[i].timestamp_creacion = fecha;

              if (firstNotices[i].timestamp_modificacion != null) {
                var diaModificacion = firstNotices[i].timestamp_modificacion.substring(8, 10);
                var mesNumeroModificacion = parseInt(firstNotices[i].timestamp_modificacion.substring(5, 7));
                var mesModificacion = "";

                switch (mesNumeroModificacion) {
                  case 1:
                    mesModificacion = 'enero';
                    break;

                  case 2:
                    mesModificacion = 'febrero';
                    break;

                  case 3:
                    mesModificacion = 'marzo';
                    break;

                  case 4:
                    mesModificacion = 'abril';
                    break;

                  case 5:
                    mesModificacion = 'mayo';
                    break;

                  case 6:
                    mesModificacion = 'junio';
                    break;

                  case 7:
                    mesModificacion = 'julio';
                    break;

                  case 8:
                    mesModificacion = 'agosto';
                    break;

                  case 9:
                    mesModificacion = 'septiembre';
                    break;

                  case 10:
                    mesModificacion = 'octubre';
                    break;

                  case 11:
                    mesModificacion = 'noviembre';
                    break;

                  case 12:
                    mesModificacion = 'diciembre';
                    break;

                  default:
                    break;
                }

                mesModificacion = window.translate('general.mes.lbl.lbl_mes_' + mesModificacion + ".valor", mesModificacion);
                var anio_modificacion = firstNotices[i].timestamp_modificacion.substring(0, 4);
                var fecha_modificacion = window.dateFormat(diaModificacion, mesModificacion, anio_modificacion);
                firstNotices[i].timestamp_modificacion = fecha_modificacion;
              } else {
                firstNotices[i].timestamp_modificacion = fecha;
              } // Agregamos el Tag para la tarjeta


              var tags_tmp = respons.success.data[i].tags;
              firstNotices[i].tags_targeta = tags_tmp;

              if (tags_tmp.length > 32) {
                firstNotices[i].tags_targeta = tags_tmp.substring(0, 32) + "...";
              }
            }

            Object.keys(respons.success.data).forEach(function (idx) {
              if (respons.success.data[idx].imagen.split('/')[0] === 'uploads') {
                respons.success.data[idx].imagen = constant.BASEURL + respons.success.data[idx].imagen;
              }

              if (respons.success.data[idx].imagen_crop.split('/')[0] === 'uploads') {
                respons.success.data[idx].imagen_crop = constant.BASEURL + respons.success.data[idx].imagen_crop;
              }
            });
            controller.set('hilo-noticias', firstNotices);
            setTimeout(function () {
              self.initRedesSociales();
            }, 0);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      } else {
        var _tipo_noticia;

        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          //data: 'params=%7B%22comunidad_k%22%3A-1%2C%22tipo%22%3A1%7D',
          xhrFields: {
            withCredentials: true
          },
          data: 'params={"noticia_k":"' + noticias_k + '"}',
          success: function success(r) {
            var respons = JSON.parse(r);
            var dia, mes, mesNumero, año, fecha;
            console.log(respons.data);

            if (respons.data[0].cuerpo.indexOf('<div>') != 0) {
              respons.data[0].cuerpo = '<div>' + respons.data[0].cuerpo + '</div>';
            }

            var detalle = Ember.$.parseHTML(respons.data[0].cuerpo, document, true);

            if (Ember.$(detalle).find('a').length) {
              Ember.$(detalle).find('a').attr('target', '_blank');
              var url = Ember.$(detalle).find('a').attr('href');

              if (url && url.indexOf('http') != 0) {
                Ember.$(detalle).find('a').attr('href', '//' + url);
              }

              respons.data[0].cuerpo = Ember.$(detalle).html();
            }

            Object.keys(respons.data).forEach(function (idx) {
              Ember.$.ajax({
                url: constant.APIURL + 'usuario/datospersonales/',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: {
                  withCredentials: true
                },
                success: function success(r) {
                  var data = JSON.parse(r);
                  console.log(data.configuracion.general_compatir_noticias_en_linkedin_habilitar); //Redes sociales

                  if (data.configuracion.general_compatir_noticias_en_facebook_habilitar == false) {
                    Ember.$("#face_vis").hide();
                  } else {
                    Ember.$("#face_vis").show();
                  }

                  if (data.configuracion.general_compatir_noticias_en_twitter_habilitar == false) {
                    Ember.$("#twitter_vis").hide();
                  } else {
                    Ember.$("#twitter_vis").show();
                  }

                  if (data.configuracion.general_compatir_noticias_en_linkedin_habilitar == false) {
                    Ember.$("#link_vis").hide();
                  } else {
                    Ember.$("#link_vis").show();
                  }
                },
                error: function error(e) {
                  console.log(e);
                }
              });
              dia = respons.data[idx].timestamp_creacion.substring(8, 10);
              mesNumero = parseInt(respons.data[idx].timestamp_creacion.substring(5, 7));

              switch (mesNumero) {
                case 1:
                  mes = 'Enero';
                  break;

                case 2:
                  mes = 'Febrero';
                  break;

                case 3:
                  mes = 'Marzo';
                  break;

                case 4:
                  mes = 'Abril';
                  break;

                case 5:
                  mes = 'Mayo';
                  break;

                case 6:
                  mes = 'Junio';
                  break;

                case 7:
                  mes = 'Julio';
                  break;

                case 8:
                  mes = 'Agosto';
                  break;

                case 9:
                  mes = 'Septiembre';
                  break;

                case 10:
                  mes = 'Octubre';
                  break;

                case 11:
                  mes = 'Noviembre';
                  break;

                case 12:
                  mes = 'Diciembre';
                  break;

                default:
                  break;
              }

              año = respons.data[idx].timestamp_creacion.substring(0, 4);
              fecha = dia + ' de ' + mes + ' de ' + año;
              respons.data[idx].timestamp_creacion = fecha;

              if (respons.data[idx].imagen.split('/')[0] === 'uploads') {
                respons.data[idx].imagen = constant.BASEURL + respons.data[idx].imagen;
              }

              _tipo_noticia = respons.data[idx].tipo;
              /*setTimeout(function () {
                  $('.cont-cuerpo-noticia-'+noticias_k+' a').click(function() {
                      var _url = $(this).attr('href');
                      self.goTrackingNoticia(noticias_k, _tipo_noticia, _url);
                      var tab = window.open(_url, '_blank');
                      if(tab){
                          tab.focus();
                      }else{
                          $('#modal-avisos .modal-header').text('Advertencia');
                          $('#modal-avisos .modal-body'  ).text('Probablemente las ventanas emergentes (Popups) están bloqueadas');
                          $('#modal-avisos').modal('show');
                          return false;
                      }
                      return false;
                  });
              }, 0);*/
            });
            controller.set('hilo-noticias_especifico', respons.data);
            setTimeout(function () {
              self.initRedesSociales();
            }, 0); //tracking

            self.goTrackingNoticia(noticias_k, _tipo_noticia, '');
            Ember.$('.btn-lista-noticias').removeClass('hideBtnListaNoticias');
            Ember.$('.bucardor_tag').hide();
          },
          error: function error(e) {
            console.error(e);
          }
        });
      }
    },
    goTrackingNoticia: function goTrackingNoticia(_noticias_k, _tipo_noticia, _url) {
      var _data;

      if (_url == '') {
        _data = 'noticia_k=' + _noticias_k + '&tipo_noticia=' + _tipo_noticia;
      } else {
        _data = 'noticia_k=' + _noticias_k + '&tipo_noticia=' + _tipo_noticia + '&url=' + _url;
      } //tracking


      Ember.$.ajax({
        url: constant.APIURL + 'noticia/agregarTrackingNoticia',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: _data,
        success: function success(response) {
          var r = JSON.parse(response).data;
          console.log(r);
        },
        error: function error(response) {
          console.log(response);
        }
      });
    },
    initRedesSociales: function initRedesSociales() {
      this._super();

      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'usuario/datospersonales/',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var data = JSON.parse(r);

          if (data.configuracion.general_compatir_noticias_en_facebook_habilitar == false) {
            Ember.$("#face_vis").hide();
          } else {
            Ember.$("#face_vis").show();
          }

          if (data.configuracion.general_compatir_noticias_en_twitter_habilitar == false) {
            Ember.$("#twitter_vis").hide();
          } else {
            Ember.$("#twitter_vis").show();
          }

          if (data.configuracion.general_compatir_noticias_en_linkedin_habilitar == false) {
            Ember.$("#link_vis").hide();
          } else {
            Ember.$("#link_vis").show();
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      Ember.$('.twitterShare').click(function (e) {
        var classRow = Ember.$(e.target).parent().parent().parent().parent().parent().children()[1];
        var classRowTextNew = Ember.$(e.target).parent().parent().parent().parent().parent().children()[4];
        var primerParrafo = Ember.$(classRowTextNew).children().children()[0];
        var primerParrafoText = Ember.$(primerParrafo).text();
        var tituloNoticia = Ember.$(classRow).text().trim();
        var twitterMsm = tituloNoticia.replace("%", " porciento") + ' -- ' + primerParrafoText.replace("%", " porciento");
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        var url = constant.SHAREURL + 'noticia/' + noticia_k;
        var width = 575,
            height = 400,
            left = (Ember.$(window).width() - width) / 2,
            top = (Ember.$(window).height() - height) / 2,
            url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
        window.open(url, 'twitter', opts);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
      });
      Ember.$('.facebookShare').click(function (e) {
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        shareFB(constant.SHAREURL + 'noticia/' + noticia_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
      });
      Ember.$('.linkedInShare').click(function (e) {
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        shareLI(constant.SHAREURL + 'noticia/' + noticia_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
      });
    },
    actions: {
      goListaNoticias: function goListaNoticias() {
        window.history.back();
        setTimeout(function () {
          document.location.reload();
        }, 500);
      },
      noticiaEspecifica: function noticiaEspecifica(path) {
        document.location.href = "#/noticias/?noticia_k=" + path;
        document.location.reload();
      },
      busqueda_por_tags: function busqueda_por_tags() {
        var tags = Ember.$("#inputTags").val().trim();

        if (tags == '') {
          alert("Para realizar la búsqueda, es necesario ingresar el nombre de algún Tag existente.");
          return false;
        }

        document.location.href = '/#/noticias/?tags=' + tags;
        document.location.reload();
      },
      limpiar_busqueda: function limpiar_busqueda() {
        document.location.href = '/#/noticias/';
        document.location.reload();
      }
    }
  });

  _exports.default = _default;
});