define("comunidades-nafin/templates/nom-037/evidencias/politicas-de-riesgo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALAO6PZ9",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[10,\"style\",\"height: auto !important;\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 pt-3\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"\"],[8],[0,\"\\n            \"],[1,[26,\"translate\",[\"nom_035_stps.carpeta_evidencias.msg.msg_descripcion_politica_riesgo.valor\",\"En esta\\n            sección podrá consultar y descargar las políticas de riesgo correspondientes a su empresa.\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\" col-12 mt-1\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"btn btn-info \"],[11,\"disabled\",[20,\"isLoading\"]],[3,\"action\",[[21,0,[]],\"showCreateModal\"]],[8],[0,\"\\n            \"],[6,\"i\"],[10,\"class\",\"fa fa-pen\"],[8],[9],[0,\" Actualizar Políticas de riesgo\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\" col-12 mt-1\"],[8],[0,\"\\n        \"],[6,\"iframe\"],[10,\"id\",\"previewDocumentIFrame\"],[10,\"src\",\"\"],[10,\"style\",\"width:100%; height:100vh;\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"nom037/politicas-de-riesgo/create-update-form-politica\",null,[[\"updateStore\",\"isVisibleCreateUpdateForm\",\"command\",\"isCommandUpdate\",\"onAfterCreate\",\"onAfterUpdate\",\"periodo_k\"],[[22,[\"updateStore\"]],[22,[\"isVisibleCreateUpdateForm\"]],[22,[\"createDeleteCommand\"]],[22,[\"isCommandUpdate\"]],[26,\"route-action\",[\"afterCreate\"],null],[26,\"route-action\",[\"afterUpdate\"],null],[22,[\"periodo_k\"]]]]],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-037/evidencias/politicas-de-riesgo.hbs"
    }
  });

  _exports.default = _default;
});