define("comunidades-nafin/routes/competencias/evaluacion-usuario/aplicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indice_competencia_seleccionada: -1,
    evaluacion_k: null,
    propietario_recurso_k: null,
    recurso_alumno_resultado_k: null,
    evaluacionData: null,
    evaluacion_nombre: '',
    evaluacion_porcentaje_avance: 95,
    updateRecord: null,
    categoriasList: [],
    competenciasList: [],
    evaluadosArray: [],
    evaluadosRetroalimentacionArray: [],
    evaluadoRetroalimentacionRecord: null,
    nivelesArray: [],
    nivelesInt: 0,
    appClima: false,
    isFeedbackEnabled: false,
    isAtLeastOneCompetenciaSelected: false,
    isVisibleSelectorCompetencias: true,
    isVisibleRaterPorUsuario: false,
    isVisibleRetroalimentacion: false,
    isWorking: false,
    isPreviousButtonEnabled: true,
    isNextButtonEnabled: true,
    indiceCompetenciaSeleccionada: 1,
    nombreCompetenciaSeleccionada: '',
    nombreCompetenciaPadreSeleccionada: '',
    numeroTotalCompetencias: 0,
    porcentajeAvanceEnEvaluacion: 0,
    indiceRetroalimentacionSeleccionada: 0,
    isHiddenInformationScreen: true,
    showRetro: false,
    BASEURL: window.constant.BASEURL,

    /**
     * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @return  void
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.evaluacion_k = this.evaluacion_k = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      controller.set('categoriasList', this.categoriasList);
      controller.set('competenciasList', this.competenciasList);
      controller.set('evaluadosArray', this.evaluadosArray);
      controller.set('evaluadosRetroalimentacionArray', this.evaluadosRetroalimentacionArray);
      controller.set('evaluadoRetroalimentacionRecord', this.evaluadoRetroalimentacionRecord);
      controller.set('nivelesArray', this.nivelesArray);
      controller.set('nivelesInt', this.nivelesInt);
      controller.set('appClima', this.appClima);
      controller.set('BASEURL', this.BASEURL);
      controller.set('isVisibleSelectorCompetencias', this.isVisibleSelectorCompetencias);
      controller.set('isVisibleRaterPorUsuario', this.isVisibleRaterPorUsuario);
      controller.set('isVisibleRetroalimentacion', this.isVisibleRetroalimentacion);
      controller.set('isWorking', this.isWorking);
      controller.set('evaluacion_k', this.evaluacion_k);
      controller.set('evaluacionData', this.evaluacionData);
      controller.set('evaluacion_nombre', this.evaluacion_nombre);
      controller.set('evaluacion_porcentaje_avance', this.evaluacion_porcentaje_avance);
      controller.get('propietario_recurso_k', this.propietario_recurso_k);
      controller.get('recurso_alumno_resultado_k', this.recurso_alumno_resultado_k);
      controller.set('updateRecord', this.updateRecord);
      controller.set('isPreviousButtonEnabled', this.isPreviousButtonEnabled);
      controller.set('isNextButtonEnabled', this.isNextButtonEnabled);
      controller.set('indiceCompetenciaSeleccionada', this.indiceCompetenciaSeleccionada);
      controller.set('nombreCompetenciaSeleccionada', this.nombreCompetenciaSeleccionada);
      controller.set('nombreCompetenciaPadreSeleccionada', this.nombreCompetenciaPadreSeleccionada);
      controller.set('numeroTotalCompetencias', this.numeroTotalCompetencias);
      controller.set('porcentajeAvanceEnEvaluacion', this.porcentajeAvanceEnEvaluacion);
      controller.set('indiceRetroalimentacionSeleccionada', this.indiceRetroalimentacionSeleccionada);
      controller.set('progressEvaluacion', 50);
      controller.set('isHiddenInformationScreen', this.isHiddenInformationScreen);
      controller.set('isAtLeastOneCompetenciaSelected', this.isAtLeastOneCompetenciaSelected);
      controller.set('isFeedbackEnabled', this.isFeedbackEnabled);
      controller.set('toggleHiddenInformationScreen', false);
      controller.set('showRetro', this.showRetro);
      this.loadEvaluacionGeneral(controller);
      this.loadEvaluacion(controller);
      this.loadEvaluadosParaRetroalimentacion(controller);
      var self = this;
      setTimeout(function () {
        self.loadEvaluacionClima(controller);
        var lista_competencias_seleccionada = self.controller.get('competenciasList');
        self.send("onClickCompetenciaCardTmp", lista_competencias_seleccionada[0]);
        setTimeout(function () {
          $('#loading').fadeOut('slow'); // Si ingreso por medio de la app de CLIMA, quitamos la pantalla de instruccion y pasa directo a la evaluación
          // if(window.constant.LANDINGURL == 'https://demo.solucioneshumantalent.com'){

          if (window.constant.LANDINGURL == 'https://climahumantalent.com/') {
            $(".contenedores-evaluacion").show();
          } else {
            $(".contenedores-evaluacion").hide();
          }
        }, 1000);
      }, 1000); //setTimeout( function () {
      //controller.send( 'seleccionarSeccionRetroalimentacion' );
      // }, 5000);
    },
    loadEvaluacionClima: function loadEvaluacionClima(controller) {
      var self = this; // Si ingreso por medio de la app de CLIMA, quitamos la pantalla de instruccion y pasa directo a la evaluación
      // if(window.constant.LANDINGURL == 'https://demo.solucioneshumantalent.com'){

      if (window.constant.LANDINGURL == 'https://climahumantalent.com/') {
        controller.set('appClima', true);
        $('#collapseExample').collapse('hide');
        var toggleHiddenInformationScreen = controller.get('toggleHiddenInformationScreen');

        if (!toggleHiddenInformationScreen) {
          $(".contenedores-evaluacion").show();
          setTimeout(function () {
            self.activarFlickity();
            $(".star-empty, .star-full").attr("height", "15%");
            $(".star-empty, .star-full").attr("width", "15%");
          }, 1000);
        } else {
          $(".contenedores-evaluacion").hide();
        }

        toggleHiddenInformationScreen = false;
        controller.set('toggleHiddenInformationScreen', toggleHiddenInformationScreen);
      }
    },

    /**
     * loadEvaluacionGeneral - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @return  void
     */
    loadEvaluacionGeneral: function loadEvaluacionGeneral(controller) {
      var evaluacion_k = controller.get('evaluacion_k');
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/listar',
        type: "post",
        async: false,
        data: {
          params: JSON.stringify({
            "evaluacion_k": evaluacion_k
          })
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          controller.set('evaluacion_nombre', response.data[0].nombre);
          controller.set('evaluacionData', response.data[0]);
          controller.set('propietario_recurso_k', response.data[0].propietario_recurso_k ? response.data[0].propietario_recurso_k : '');
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },

    /**
     * loadEvaluacion - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @return  void
     */
    loadEvaluacion: function loadEvaluacion(controller) {
      var lista_competencias = [];
      var evaluacion_k = controller.get('evaluacion_k');
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/cargarevaluar',
        type: "post",
        data: {
          e: evaluacion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var lista_competencias = [];
          response.competencias.data.forEach(function (competentenciaObject, index) {
            competentenciaObject.indiceCompetencia = index + 1;
            lista_competencias.push(competentenciaObject);
          });

          if (response.config.retroalimentacion.encuesta_retroalimentacion_cliente != null || response.config.retroalimentacion.encuesta_retroalimentacion_colaborador != null || response.config.retroalimentacion.encuesta_retroalimentacion_par != null || response.config.retroalimentacion.encuesta_retroalimentacion_jefe != null || response.config.retroalimentacion.encuesta_retroalimentacion_autoevaluacion != null) {
            controller.set('showRetro', true);
          } else {
            controller.set('showRetro', false);
          }

          lista_competencias = lista_competencias.filter(function (el) {
            return el != null;
          });

          if (lista_competencias.length > 0) {
            if (lista_competencias[0].calificacion != null) {
              controller.set('isAtLeastOneCompetenciaSelected', true);
            }
          }

          controller.set('competenciasList', lista_competencias);
          controller.set('numeroTotalCompetencias', lista_competencias.length);

          if (response.config.comments == 1) {
            controller.set('isFeedbackEnabled', true);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },

    /**
     * loadEvaluadosPorCompetencia - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @return  void
     */
    loadEvaluadosPorCompetencia: function loadEvaluadosPorCompetencia(competenciaRecord) {
      var self = this;
      this.controller.set('nivelesArray', []);
      /*
       *  Cargamos el listado de
       */

      $(".star-empty, .star-full").attr("height", "15%");
      $(".star-empty, .star-full").attr("width", "15%");
      $.ajax({
        url: constant.APIURL + 'competencia/listarniveles',
        type: "post",
        data: {
          idCompetencia: competenciaRecord.competencia_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        async: false,
        dataType: "json",
        success: function success(response) {
          self.controller.set('nivelesArray', response.data);
          self.controller.set('nivelesInt', response.numFilas);
          $(".star-empty, .star-full").attr("height", "15%");
          $(".star-empty, .star-full").attr("width", "15%");
          setTimeout(function () {
            $(".star-empty, .star-full").attr("height", "15%");
            $(".star-empty, .star-full").attr("width", "15%");
          }, 100);
          setTimeout(function () {
            //$('.evaluado-container').show();
            $(".star-empty, .star-full").show();
          }, 2000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/loadevaluadosxcompetencia',
        type: "post",
        data: {
          e: this.evaluacion_k,
          c: competenciaRecord.competencia_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var evaluadosArray = [];
          response.data.forEach(function (evaluadoObject) {
            evaluadosArray.push(evaluadoObject);
          });
          self.controller.set('evaluadosArray', evaluadosArray);
          self.controller.set('isVisibleSelectorCompetencias', true);
          self.controller.set('isVisibleRaterPorUsuario', true);
          self.controller.set('isVisibleRetroalimentacion', false);
          setTimeout(function () {
            $(".star-empty, .star-full").attr("height", "15%");
            $(".star-empty, .star-full").attr("width", "15%");
          }, 100);
          setTimeout(function () {//$('.evaluado-container').show();
          }, 2000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },

    /**
     * loadEvaluadosParaRetroalimentacion - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @return  void
     */
    loadEvaluadosParaRetroalimentacion: function loadEvaluadosParaRetroalimentacion(controller) {
      var self = this;
      var evaluacion_k = this.controller.get('evaluacion_k');
      var propietario_recurso_k = this.controller.get('propietario_recurso_k');
      controller.set('evaluadosRetroalimentacionArray', []);
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/listarevaluados',
        type: "post",
        data: {
          evaluacion_k: evaluacion_k,
          propietario_recurso_k: propietario_recurso_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var evaluadosRetroalimentacionArrayTemporal = [];
          var evaluadosRetroalimentacionArray = [];
          response.data.forEach(function (evaluadoObject) {
            evaluadosRetroalimentacionArrayTemporal[evaluadoObject.usuario_k] = evaluadoObject;
          });
          evaluadosRetroalimentacionArrayTemporal.forEach(function (record, index) {
            evaluadosRetroalimentacionArray.push(record);
          });
          evaluadosRetroalimentacionArray.forEach(function (record, index) {
            record.index = index;
          });
          controller.set('evaluadosRetroalimentacionArray', evaluadosRetroalimentacionArray);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },

    /**
     * actualizarPorcentajeAvance - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     * @param   void
     * @param   void
     * @return  void
     */
    actualizarPorcentajeAvance: function actualizarPorcentajeAvance() {
      var numeroTotalDeCompetencias = 0;
      var numeroCompetenciasalificadas = 0;
      var competenciasAgrupadas = this.controller.get('competenciasAgrupadas');
      competenciasAgrupadas.forEach(function (listaCompetenciasIteracion) {
        listaCompetenciasIteracion.forEach(function (registroCompetenciaIteracion) {
          numeroTotalDeCompetencias++;

          if (parseInt(registroCompetenciaIteracion.calificacion) > 0) {
            numeroCompetenciasCalificadas++;
          }
        });
      });
      var avance_porcentaje = Math.round(numeroCompetenciasCalificadas * 100 / numeroTotalDeCompetencias * 100) / 100;
      this.controller.set('evaluacion_porcentaje_avance', avance_porcentaje);
    },
    activarFlickity: function activarFlickity() {
      /*
       *
       * @type {Array}
       */
      var alturas = [];
      $('div.carousel-cell div.card').each(function () {
        alturas.push($(this).height());
      });
      var maximo = Math.max.apply(null, alturas);
      $('div.carousel-cell div.card').each(function () {
        //Aplicamos a todos los divs la misma altura
        $(this).height(maximo);
      });
      /*
       *
       */

      $('.main-carousel').flickity({
        pageDots: false,
        dragThreshold: 32,
        initialIndex: 0,
        cellAlign: 'center',

        /* Quitamos el drag para no ver las siguientes preguntas */
        draggable: false,
        accessibility: false
      });
    },
    actualizarProgresoAvanceEvaluacion: function actualizarProgresoAvanceEvaluacion() {
      var competenciasList = this.controller.get('competenciasList');
      var numero_total_competencias = competenciasList.length;
      var numero_competencias_concluidas = 0;
      competenciasList.forEach(function (competenciaRecord) {
        if (competenciaRecord.estatus == 'status_closed') {
          numero_competencias_concluidas++;
        }
      });
      var porcentaleAvanceEnEvaluacion = parseInt(numero_competencias_concluidas * 100 / numero_total_competencias);
      this.controller.set('porcentajeAvanceEnEvaluacion', porcentaleAvanceEnEvaluacion);
    },

    /**
     * retroalimentacionGuardarCambios - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     */
    retroalimentacionGuardarCambios: function retroalimentacionGuardarCambios(evaluadoRetroalimentacionRecord, pregutaObject, nueva_respuesta, propietario_recurso_k) {
      var recurso_alumno_resultado_k = this.controller.get('recurso_alumno_resultado_k');
      var evaluado = this.controller.get('detallesEvaluado');
      var nObject = pregutaObject;
      var respuesta_k = "";
      var pregunta_k = "";

      for (var i = 0; i < pregutaObject.respuestas.length; i++) {
        if (pregutaObject.respuestas[i].correcta != "") {
          pregunta_k = pregutaObject.pregunta_k;
          respuesta_k = pregutaObject.respuestas[i].respuesta_k;
        }
      }

      var recordToUpdateObject = {
        'recurso_alumno_resultado_k': evaluadoRetroalimentacionRecord.recurso_alumno_resultado_k,
        'propietario_recurso_k': propietario_recurso_k,
        'intento_ra': 0,
        'grupo_k': 0,
        'usuario_k': evaluado.usuario_k,
        respuesta_k: respuesta_k,
        pregunta_k: pregunta_k,
        'nueva_respuesta': nueva_respuesta,
        'preguntas': JSON.stringify([pregutaObject]),
        nObject: JSON.stringify([nObject])
      };
      $.ajax({
        url: constant.APIURL + '/lms/encuesta/guardarcambios',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: recordToUpdateObject,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var response = JSON.parse(response_string);

          if (response.success) {} else {}
        },
        error: function error(_error) {}
      });
    },

    /**
     * finalizarEvaluacion - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     */
    finalizarEvaluacion: function finalizarEvaluacion() {
      var evaluacion_k = this.get('evaluacion_k');
      var recordToUpdateObject = {
        e: evaluacion_k,
        datos: JSON.stringify([])
      };
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/calificar',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: recordToUpdateObject,
        contenType: 'application/x-www-form-urlencoded',
        beforeSend: function beforeSend() {
          $('#msj_guardado').show();
          $('.btn_guardar_evaluacion').hide();
        },
        success: function success(response) {
          setTimeout(function () {
            $('#modal-avisos').modal('hide');

            if (localStorage.getItem("logout_after_complete") && localStorage.getItem("logout_after_complete") != null) {
              $.ajax({
                url: constant.APIURL + 'usuario/logout/',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: {
                  withCredentials: true
                },
                success: function success(r) {
                  $("body").css("padding-top", "0px");
                  $("body").html("<div class='row justify-content-center' style='height:100vh'><div class='col-11 col-sn-11 col-md-6 col-lg-4 text-center my-auto'><div class='card' ><div class='card-img-top py-3' style='background-color:" + $("#mainNav").css("background-color") + "'><img src='" + "lookandfeel/" + window.constant.THEME + "/img/logo.png" + "' width='100px' /></div><div class='card-body'>" + localStorage.getItem("mensaje_final_evaluacion") + "</div></div></div></div>");
                  localStorage.clear();
                },
                error: function error(e) {
                  console.log(e);
                }
              });
            } else {
              var _redirect_path = '/#/comunidades';

              if (_redirect_path != '') {
                document.location.href = window.usuarioConfiguracion.url_redireccion_login;
              }
            }
          }, 3500);
        },
        error: function error(e) {
          console.log(e);
          $('#msj_guardado').hide();
          $('#btn_guardar_evaluacion').show();
        }
      });
    },

    /**
     * actions - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-05-25
     */
    actions: {
      /**
       * onClickCategoriaCard - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      onClickCategoriaCard: function onClickCategoriaCard(categoriaRecord) {
        var self = this;
        var lista_de_categorias = this.controller.get('categoriasList');
        var lista_competencias_agrupadas = this.controller.get('competenciasAgrupadas');
        var lista_competencias_seleccionada = this.controller.get('competenciasList');
        /*
         * Iteramos en cada una de las categorias para identificar la ubicación de la lista de competencias.
         */

        var indice_de_categoria_seleccionada = -1;
        lista_de_categorias.forEach(function (categoriaIteracionObject, categoriaIteracionIndex) {
          if (Ember.get(categoriaRecord, 'nombre') == Ember.get(categoriaIteracionObject, 'nombre') && Ember.get(categoriaRecord, 'orden') == Ember.get(categoriaIteracionObject, 'orden')) {
            indice_de_categoria_seleccionada = categoriaIteracionIndex;
          }
        });

        if (indice_de_categoria_seleccionada != -1) {
          this.controller.set('competenciasList', lista_competencias_agrupadas[indice_de_categoria_seleccionada]);
        }

        $('#loading').show();

        if ($('.main-carousel').flickity) {
          $('.main-carousel').flickity('destroy');
        }

        this.controller.set('isVisibleSelectorCompetencias', true);
        this.controller.set('isVisibleRaterPorUsuario', false);
        /* false */

        this.controller.set('isVisibleRetroalimentacion', false);
        var self = this;
        setTimeout(function () {
          self.activarFlickity();
          $('#loading').fadeOut('slow');
          /*
           *
           * Carga la primer competencia de la categoria
           *
           */

          var lista_competencias_seleccionada = self.controller.get('competenciasList');
          self.send("onClickCompetenciaCardTmp", lista_competencias_seleccionada[0]);
          $(".star-empty, .star-full").attr("height", "15%");
          $(".star-empty, .star-full").attr("width", "15%");
        }, 175);
      },

      /**
       * ShowSelectorCategorias - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      ShowSelectorCategorias: function ShowSelectorCategorias() {
        var competenciasList = this.controller.get('competenciasList');
        competenciasList.forEach(function (competenciaObject) {
          Ember.set(competenciaObject, 'seleccionado', false);
        });
        this.controller.set('competenciasList', competenciasList);
        this.controller.set('isVisibleSelectorCompetencias', false);
        this.controller.set('isVisibleRaterPorUsuario', true);
        /*  */

        this.controller.set('isVisibleRetroalimentacion', false);
      },

      /**
       * onClickCompetenciaCard - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      onClickCompetenciaCard: function onClickCompetenciaCard(competenciaRecord) {
        /* Si la app es de Clima y no se ha evaluado la competencia no podra seleccionarse */
        if (window.constant.configuracionEmpresa.empresa_k == '79' && competenciaRecord.estatus === null) {
          return false;
        }

        var competenciasList = this.controller.get('competenciasList');
        var self = this;
        competenciasList.forEach(function (competenciaObject, inidice_iteracion_competencia) {
          Ember.set(competenciaObject, 'seleccionado', false);
        });
        this.indice_competencia_seleccionada = competenciaRecord.indiceCompetencia - 1;
        this.controller.set('indiceCompetenciaSeleccionada', competenciaRecord.indiceCompetencia);
        this.controller.set('nombreCompetenciaSeleccionada', competenciaRecord.categorias);
        this.controller.set('nombreCompetenciaPadreSeleccionada', competenciaRecord.categoria_padre);
        this.controller.set('competenciasList', competenciasList);
        Ember.set(competenciaRecord, 'seleccionado', true);
        $(".star-empty, .star-full").attr("height", "15%");
        $(".star-empty, .star-full").attr("width", "15%");
        this.actualizarProgresoAvanceEvaluacion();
        this.loadEvaluadosPorCompetencia(competenciaRecord);
      },
      onClickCompetenciaCardTmp: function onClickCompetenciaCardTmp(competenciaRecord) {
        var competenciasList = this.controller.get('competenciasList');
        var self = this;
        competenciasList.forEach(function (competenciaObject, inidice_iteracion_competencia) {
          Ember.set(competenciaObject, 'seleccionado', false);
        });
        this.indice_competencia_seleccionada = competenciaRecord.indiceCompetencia - 1;
        this.controller.set('indiceCompetenciaSeleccionada', competenciaRecord.indiceCompetencia);
        this.controller.set('nombreCompetenciaSeleccionada', competenciaRecord.categorias);
        this.controller.set('nombreCompetenciaPadreSeleccionada', competenciaRecord.categoria_padre);
        this.controller.set('competenciasList', competenciasList);
        Ember.set(competenciaRecord, 'seleccionado', true);
        $(".star-empty, .star-full").attr("height", "15%");
        $(".star-empty, .star-full").attr("width", "15%");
        this.actualizarProgresoAvanceEvaluacion();
        this.loadEvaluadosPorCompetencia(competenciaRecord);
      },

      /**
       * seleccionarSiguienteCompetencia - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      seleccionarSiguienteCompetencia: function seleccionarSiguienteCompetencia() {
        console.log("Entra");
        var indice_siguiente_competencia_seleccionada = this.indice_competencia_seleccionada + 1;
        var competenciasList = this.controller.get('competenciasList');
        var self = this;
        var isCompetenciaSeleccionada = false;
        /*
         * iteramos para identificar su la siguiente competencia esta disponible para selección.
         */

        competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {
          if (indice_siguiente_competencia_seleccionada == indice_iteracion_competencias) {
            console.log(indice_siguiente_competencia_seleccionada, indice_iteracion_competencias);
            isCompetenciaSeleccionada = true;
          }
        });
        /*
         * Registramos la selección de la anterior competencia y realizamos la carga de evaluados.
         */

        if (isCompetenciaSeleccionada) {
          console.log("Entra if");
          competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {
            Ember.set(competenciaObject, 'seleccionado', false);

            if (indice_siguiente_competencia_seleccionada == indice_iteracion_competencias) {
              self.controller.set('indiceCompetenciaSeleccionada', competenciaObject.indiceCompetencia);
              self.controller.set('nombreCompetenciaSeleccionada', competenciaObject.categorias);
              self.controller.set('nombreCompetenciaPadreSeleccionada', competenciaObject.categoria_padre);
              Ember.set(competenciaObject, 'seleccionado', true);
            }
          });
          this.controller.set('competenciasList', competenciasList);
          /* Validamos si la competencia actual es evaluada, de lo contrario no podra avanzar */

          var irSiguienteCompetencia = true;

          if (window.constant.configuracionEmpresa.empresa_k == '79') {
            competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {
              if (self.indice_competencia_seleccionada == indice_iteracion_competencias && competenciaObject.estatus === null) {
                console.log('self.indice_competencia_seleccionada ', self.indice_competencia_seleccionada);
                console.log('competenciaObject.nombre', competenciaObject.nombre);
                irSiguienteCompetencia = false;
              }
            });
          }

          if (!irSiguienteCompetencia) {
            console.log("Es necesario evaluar la competencia actual.");
            showModal({
              titulo: 'Competencia sin calificar.',
              mensaje: 'Es necesario calificar este rubro para poder continuar con la evaluación.'
            });
            var indice_competencia_sinresponder = this.controller.get('competenciasList');
            self.send("onClickCompetenciaCardTmp", indice_competencia_sinresponder[self.indice_competencia_seleccionada]);

            if ($('.main-carousel').flickity) {
              $('.main-carousel').flickity('select', self.indice_competencia_seleccionada, false, false);
            }
          } else {
            this.indice_competencia_seleccionada = indice_siguiente_competencia_seleccionada;
            this.loadEvaluadosPorCompetencia(competenciasList[indice_siguiente_competencia_seleccionada]);

            if ($('.main-carousel').flickity) {
              $('.main-carousel').flickity('select', indice_siguiente_competencia_seleccionada, false, false);
              $(".star-empty, .star-full").attr("height", "15%");
              $(".star-empty, .star-full").attr("width", "15%");
            }
          }
        } else {
          console.log("Entra else");
          var competenciasList = this.controller.get('competenciasList');
          var contador_competencias_evaluadas = 0;
          var nombre_compentencia_faltante = '';
          var indice_competencia_faltante = 0;
          competenciasList.forEach(function (competenciaObject, indexCompetencia) {
            if (Ember.get(competenciaObject, 'estatus') != 'status_closed') {
              contador_competencias_evaluadas++;

              if (contador_competencias_evaluadas == 1) {
                nombre_compentencia_faltante = Ember.get(competenciaObject, 'nombre');
                indice_competencia_faltante = indexCompetencia;
              }
            }
          });

          if (contador_competencias_evaluadas != 0) {
            console.log("Competencias sin evaluar");
            showModal({
              titulo: 'Competencias sin calificar.',
              mensaje: 'La competencia &quot;<b>' + nombre_compentencia_faltante + ' </b>&quot; aun requiere calificacion'
            });
            var self = this;
            setTimeout(function () {
              self.loadEvaluadosPorCompetencia(competenciasList[indice_competencia_faltante]);
              self.indice_competencia_seleccionada = indice_competencia_faltante;
              self.controller.set('indiceCompetenciaSeleccionada', indice_competencia_faltante + 1);
              competenciasList.forEach(function (competenciaObject) {
                Ember.set(competenciaObject, 'seleccionado', false);
              });
              Ember.set(competenciasList[indice_competencia_faltante], 'seleccionado', true);

              if ($('.main-carousel').flickity) {
                $('.main-carousel').flickity('select', indice_competencia_faltante, false, false);
              }
            }, 1000);
          } else {
            console.log("Competencias todas evaluadas");

            if (self.controller.get("showRetro")) {
              this.send('seleccionarSeccionRetroalimentacion');
            } else {
              showModal({
                titulo: 'Aviso',
                mensaje: 'La evaluación ha concluido, por favor espere'
              });
              self.finalizarEvaluacion();
              $("#cerrar").hide();
            }
          }
        }

        this.actualizarProgresoAvanceEvaluacion();
      },

      /**
       * seleccionarAnteriorCompetencia - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      seleccionarAnteriorCompetencia: function seleccionarAnteriorCompetencia() {
        var indice_anterior_competencia_seleccionada = this.indice_competencia_seleccionada - 1;
        var competenciasList = this.controller.get('competenciasList');
        var isCompetenciaSeleccionada = false;
        /*
         * iteramos para identificar su la siguiente competencia esta disponible para selección.
         */

        competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {
          if (indice_anterior_competencia_seleccionada == indice_iteracion_competencias) {
            isCompetenciaSeleccionada = true;
          }
        });
        /*
         * Registramos la selección de la anterior competencia y realizamos la carga de evaluados.
         */

        if (isCompetenciaSeleccionada) {
          competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {
            Ember.set(competenciaObject, 'seleccionado', false);

            if (indice_anterior_competencia_seleccionada == indice_iteracion_competencias) {
              Ember.set(competenciaObject, 'seleccionado', true);
            }
          });
          this.controller.set('competenciasList', competenciasList);
          this.indice_competencia_seleccionada = indice_anterior_competencia_seleccionada;
          this.loadEvaluadosPorCompetencia(competenciasList[indice_anterior_competencia_seleccionada]);

          if ($('.main-carousel').flickity) {
            $('.main-carousel').flickity('select', indice_anterior_competencia_seleccionada, false, false);
          }
        }
      },

      /**
       * validarBotones - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      validarBotones: function validarBotones() {
        var competenciasList = this.controller.get('competenciasList');
        var indice_competenci_seleccionada = this.indice_competencia_seleccionada;

        if (indice_competenci_seleccionada <= 0) {
          this.controller.set('isPreviousButtonEnabled', false);
        } else {
          this.controller.set('isPreviousButtonEnabled', true);
        }

        if (indice_competenci_seleccionada + 1 >= competenciasList.length) {
          this.controller.set('isNextButtonEnabled', false);
        } else {
          this.controller.set('isNextButtonEnabled', true);
        }
      },

      /**
       * showModalCapturaFeedback - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      showModalCapturaFeedback: function showModalCapturaFeedback(evaluadoRecord) {
        this.controller.set('updateRecord', evaluadoRecord);
        $('#inputComentario').val(evaluadoRecord.comentario);
        $('#e360PanelRetroalimentacion').modal('show');
      },

      /**
       * afterCreateOrUpdate - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      afterCreateOrUpdate: function afterCreateOrUpdate() {
        this.controller.set('updateRecord', null);
        $('#inputComentario').val('');
        $('#e360PanelRetroalimentacion').modal('hide');
      },

      /**
       * onClickOnStarRating - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      onClickOnStarRating: function onClickOnStarRating(evaluadoRecord, new_rating) {
        Ember.set(evaluadoRecord, 'calificacion', new_rating);
        var recordToUpdateObject = {
          e: this.evaluacion_k,
          datos: JSON.stringify([{
            "competencia_k": evaluadoRecord.competencia_k,
            "data": [{
              "evaluado_k": evaluadoRecord.evaluado_k,
              "calificacion": new_rating,
              "evidencia_producto": evaluadoRecord.nivel_evidencia_producto,
              "comentario": evaluadoRecord.comentario
            }]
          }])
        };
        $.ajax({
          url: constant.APIURL + '/e360/evaluacion360/guardar',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          data: recordToUpdateObject,
          contenType: 'application/x-www-form-urlencoded',
          success: function success(response_string) {
            var response = JSON.parse(response_string);

            if (!response.success) {
              Ember.set(evaluadoRecord, 'calificacion', null);
            }
          },
          error: function error(_error2) {}
        });
        this.controller.set('isAtLeastOneCompetenciaSelected', true);
        var evaluadosArray = this.controller.get('evaluadosArray');
        var numero_evaluados_totales = evaluadosArray.length;
        var numero_evaluados_calificados = 0;
        evaluadosArray.forEach(function (evaluadoRecord) {
          if (evaluadoRecord.calificacion) {
            numero_evaluados_calificados++;
          }
        });

        if (numero_evaluados_calificados == numero_evaluados_totales) {
          var competenciasList = this.controller.get('competenciasList');
          competenciasList.forEach(function (competenciaObject) {
            if (evaluadoRecord.competencia_k == competenciaObject.competencia_k) {
              Ember.set(competenciaObject, 'estatus', 'status_closed');
            }
          });
        }
      },

      /**
       * verificarCompetencias - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      verificarCompetencias: function verificarCompetencias() {
        var competenciasList = this.controller.get('competenciasList');
        competenciasList.forEach(function (competenciaObject, indice_iteracion_competencias) {});
      },

      /**
       * lanzarmensajeCompetenciasSinCalificar - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      lanzarmensajeCompetenciasSinCalificar: function lanzarmensajeCompetenciasSinCalificar() {
        var mensajeCompetenciasSinCalificar = window.translate("competencias.competencias_aplicacion_evaluacion.msg.label_msg_sin_calificar.valor", "Existen competencias sin calificar. Para continuar con la evaluaci&oacute;n es necesario que todas las competencias se encuentren calificadas.");
        showModal({
          titulo: 'Competencias sin calificar',
          mensaje: mensajeCompetenciasSinCalificar
        });
      },

      /**
       * actualizarPorcentajeAvance - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      actualizarPorcentajeAvance: function actualizarPorcentajeAvance() {
        this.actualizarPorcentajeAvance();
      },

      /**
       * ShowRetroalimentacionPorUsuario - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      ShowRetroalimentacionPorUsuario: function ShowRetroalimentacionPorUsuario(evaluadoRetroalimentacionRecord, indiceRetroalimentacionSeleccionada) {
        var continuarSiguiente = true;

        if (this.controller.get('indiceRetroalimentacionSeleccionada') < Ember.get(evaluadoRetroalimentacionRecord, 'index')) {
          $("textarea.respuestas").each(function () {
            if ($(this).val().length < 2) {
              continuarSiguiente = false;
              $([document.documentElement, document.body]).animate({
                scrollTop: $(this).offset().top
              }, 0); //if( $('.main-carousel-retroalimentacion').flickity ){
              //  $('.main-carousel-retroalimentacion').flickity( 'select', 0  , false, false )
              // }
              //self.send( 'ShowRetroalimentacionPorUsuario', evaluadosRetroalimentacionArray[0],0 );

              /*showModal(
                  {
                      titulo:     'Aviso',
                      mensaje:    'Aun tienes retroalimentaciones por completar'
                  }
              );*/

              $(this).focus();
              return false;
            }
          });
        }

        if (continuarSiguiente) {
          var self = this;
          var evaluacion_k = this.controller.set('evaluacion_k');
          var propietario_recurso_k = this.controller.get('propietario_recurso_k');
          this.controller.set('detallesEvaluado', evaluadoRetroalimentacionRecord);
          this.controller.set('retroalimentacionesCompletadas', false);
          /*
           *
           */

          var evaluadosRetroalimentacionArray = self.controller.get('evaluadosRetroalimentacionArray');
          this.controller.set('indiceRetroalimentacionSeleccionada', Ember.get(evaluadoRetroalimentacionRecord, 'index'));

          if (Ember.get(evaluadoRetroalimentacionRecord, 'index') + 1 == evaluadosRetroalimentacionArray.length) {
            this.controller.set('retroalimentacionesCompletadas', true);
          }

          evaluadosRetroalimentacionArray.forEach(function (retroalimentacionUsuarioRecord) {
            Ember.set(retroalimentacionUsuarioRecord, 'seleccionado', 0);
          });
          Ember.set(evaluadoRetroalimentacionRecord, 'seleccionado', 1);
          self.controller.set('evaluadosRetroalimentacionArray', evaluadosRetroalimentacionArray);

          if ($('.main-carousel-retroalimentacion').flickity) {
            $('.main-carousel-retroalimentacion').flickity('select', Ember.get(evaluadoRetroalimentacionRecord, 'index'), false, false);
          }
          /*
           *
           */


          this.controller.set('evaluadoRetroalimentacionRecord', null);
          var recordParamsObject = {
            evaluacion_k: evaluacion_k,
            evaluado_k: evaluadoRetroalimentacionRecord.usuario_k,
            propietario_recurso_k: propietario_recurso_k
          };
          $.ajax({
            url: constant.APIURL + '/e360/evaluacion360/getDatosEvaluado',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            data: recordParamsObject,
            contenType: 'application/x-www-form-urlencoded',
            success: function success(response_string) {
              var response = JSON.parse(response_string);

              if (response.success) {
                self.controller.set('evaluadoRetroalimentacionRecord', response.encuesta);
                self.controller.set('recurso_alumno_resultado_k', response.recurso_alumno_resultado_k);
                setTimeout(function () {
                  $('textarea').keyup(function () {
                    // Si la encuesta es de ARRENDAMEX, quitamos la validacion de los numeros
                    if (response.encuesta.encuesta_k == '80') {
                      console.log("Validacion de encuesta 2");
                    } else {
                      if ($(this).val()[0] != null && $(this).val()[0] != " " && $(this).val().match(/[a-z]/i)) {
                        $(this).val($(this).val().replace(/[^\A-zÀ-ú\s\.\,]+/g, '').replace(/[0-9]/g, ""));
                      } else {
                        $(this).val("");
                      }
                    }
                  });
                  $('textarea').css("resize", "none");
                  $('textarea').attr("placeholder", "Respuesta");
                }, 2000);
              } else {}
            },
            error: function error(_error3) {}
          });
        }
      },

      /**
       * seleccionarSeccionRetroalimentacion - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      seleccionarSeccionRetroalimentacion: function seleccionarSeccionRetroalimentacion() {
        this.controller.set('isVisibleSelectorCompetencias', false);
        this.controller.set('isVisibleRaterPorUsuario', false);
        this.controller.set('isVisibleRetroalimentacion', true);
        var self = this;
        setTimeout(function () {
          console.log("Tratamos de abrir la retro");
          $('.main-carousel-retroalimentacion').flickity({
            pageDots: false,
            dragThreshold: 32,
            initialIndex: 0,
            cellAlign: 'center'
          });

          if ($('.main-carousel-retroalimentacion').flickity) {
            $('.main-carousel-retroalimentacion').flickity('select', 0, false, false);
          }

          var evaluadosRetroalimentacionArray = self.controller.get('evaluadosRetroalimentacionArray');
          console.log("evaluadosRetroalimentacionArray", evaluadosRetroalimentacionArray);
          Ember.set(evaluadosRetroalimentacionArray[0], 'seleccionado', 1);
          self.controller.set('evaluadosRetroalimentacionArray', evaluadosRetroalimentacionArray);
          self.send('ShowRetroalimentacionPorUsuario', evaluadosRetroalimentacionArray[0], 0);
        }, 250);
      },

      /**
       * IniciarEvaluacion - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      IniciarEvaluacion: function IniciarEvaluacion() {
        var lista_de_categorias = this.controller.get('categoriasList');
        this.send('onClickCategoriaCard', lista_de_categorias[0]);
      },

      /**
       * seleccionarSiguienteEncuesta - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2021-05-25
       * @param   void
       * @return  void
       */
      seleccionarSiguienteEncuesta: function seleccionarSiguienteEncuesta() {
        var evaluadoRetroalimentacionRecord = this.controller.get('evaluadoRetroalimentacionRecord');
      },
      inputOnChangeGuardarRespuesta: function inputOnChangeGuardarRespuesta(evaluadoRetroalimentacionRecord, recordObject, event) {
        var respuesta_actualizada = $('#' + event.target.id).prop('value');
        var propietario_recurso_k = this.controller.get('propietario_recurso_k');
        this.retroalimentacionGuardarCambios(evaluadoRetroalimentacionRecord, recordObject, respuesta_actualizada, propietario_recurso_k);
      },
      toggleHiddenInformationScreen: function toggleHiddenInformationScreen() {
        var self = this;
        var toggleHiddenInformationScreen = this.controller.get('toggleHiddenInformationScreen');
        console.log('toggleHiddenInformationScreen', toggleHiddenInformationScreen);

        if (!toggleHiddenInformationScreen) {
          $(".contenedores-evaluacion").show();
          self.activarFlickity();
          $(".star-empty, .star-full").attr("height", "15%");
          $(".star-empty, .star-full").attr("width", "15%");
        } else {
          $(".contenedores-evaluacion").hide();
        } //$(".carrusel-container").hide();


        toggleHiddenInformationScreen = toggleHiddenInformationScreen == true ? false : true;
        this.controller.set('toggleHiddenInformationScreen', toggleHiddenInformationScreen);
      },
      seleccionarSiguienteRetroalimentacion: function seleccionarSiguienteRetroalimentacion() {
        var continuarSiguiente = true;
        /* QUITAR LA VALIDACION PARA LA EMPRESA GS1 */

        if (window.constant.configuracionEmpresa.empresa_k != '79') {
          $("textarea.respuestas").each(function () {
            if ($(this).val().length < 2) {
              $(this).focus();
              continuarSiguiente = false;
              $([document.documentElement, document.body]).animate({
                scrollTop: $(this).offset().top
              }, 0); //if( $('.main-carousel-retroalimentacion').flickity ){
              //  $('.main-carousel-retroalimentacion').flickity( 'select', 0  , false, false )
              // }
              //self.send( 'ShowRetroalimentacionPorUsuario', evaluadosRetroalimentacionArray[0],0 );

              /*showModal(
                  {
                      titulo:     'Aviso',
                      mensaje:    'Aun tienes retroalimentaciones por completar'
                  }
              );*/

              return false;
            }
          });
        }

        if (continuarSiguiente) {
          var indiceRetroalimentacionSeleccionada = this.controller.get('indiceRetroalimentacionSeleccionada');
          var evaluadosRetroalimentacionArray = this.controller.get('evaluadosRetroalimentacionArray');
          var self = this;

          if (indiceRetroalimentacionSeleccionada + 1 < evaluadosRetroalimentacionArray.length) {
            this.controller.set('retroalimentacionesCompletadas', false);

            if ($('.main-carousel-retroalimentacion').flickity) {
              $('.main-carousel-retroalimentacion').flickity('select', 0, false, false);
            }

            indiceRetroalimentacionSeleccionada++;
            Ember.set(evaluadosRetroalimentacionArray[indiceRetroalimentacionSeleccionada], 'seleccionado', 1);
            this.controller.set('evaluadosRetroalimentacionArray', evaluadosRetroalimentacionArray);
            this.send('ShowRetroalimentacionPorUsuario', evaluadosRetroalimentacionArray[indiceRetroalimentacionSeleccionada], indiceRetroalimentacionSeleccionada);
            this.controller.set('indiceRetroalimentacionSeleccionada', indiceRetroalimentacionSeleccionada);
          } else {
            showModal({
              titulo: 'Aviso',
              mensaje: 'Gracias, la evaluación ha concluido'
            });
            this.finalizarEvaluacion();
          }
        }
      }
    }
  });

  _exports.default = _default;
});