define("comunidades-nafin/routes/lms/reportes/seguimiento_individual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var usuario_k = window.datosPersonales.usuario_k;
      var reporte = 'https://datastudio.google.com/embed/reporting/25ce3dca-e389-4956-95f6-31a39cf8227e/page/eiRwC/?params=%7B"df4":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580' + usuario_k + '"%7D';
      controller.set('reporte', reporte);
    }
  });

  _exports.default = _default;
});