define("comunidades-nafin/templates/components/empresa/description-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h9ucpmdz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 col-lg-4 mb-4 mt-4\"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"card text-white\"],[8],[0,\"\\n            \"],[6,\"img\"],[10,\"class\",\"card-img\"],[11,\"src\",[27,[[22,[\"empresaObject\",\"logotipo_empresa_ruta_imagen\"]]]]],[10,\"alt\",\"Card image\"],[10,\"style\",\"width: 100%;\"],[8],[9],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"card-img\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 col-lg-8\"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n                \"],[6,\"h2\"],[10,\"class\",\"titulo-seccion\"],[8],[6,\"b\"],[8],[1,[22,[\"empresaObject\",\"empresa\"]],false],[9],[9],[0,\"\\n                    \"],[6,\"p\"],[10,\"style\",\"white-space: pre-line\"],[8],[0,\"\\n                       \"],[1,[22,[\"empresaObject\",\"empresa_descripcion\"]],true],[0,\"\\n                    \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/empresa/description-panel.hbs"
    }
  });

  _exports.default = _default;
});