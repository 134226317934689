define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias-bienvenida", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var periodo_k = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      controller.set('periodo_k', periodo_k);
    }
  });

  _exports.default = _default;
});