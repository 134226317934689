define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/resultados-guia-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isLoading: false,
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('isLoading', this.isSearching);
      controller.set('periodo_k', model.periodo_k);
      $.ajax({
        url: constant.APIURL + 'nom035stps/guia3/geturl',
        type: "post",
        data: {
          periodo_k: model.periodo_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('guia3Url', response.data.url_reporte);
            $("#reporte-guia-3-datastudio").attr("src", response.data.url_reporte);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      loadGuia3: function loadGuia3() {
        var self = this;
        this.controller.set('isLoading', true);
        $("#reporte-guia-3-datastudio").attr("src", this.controller.get('guia3Url'));
      }
    }
  });

  _exports.default = _default;
});