define("comunidades-nafin/components/ember-image-slider", ["exports", "ember-image-slider/components/ember-image-slider"], function (_exports, _emberImageSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberImageSlider.default;
    }
  });
});