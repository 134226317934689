define("comunidades-nafin/components/human-talent-components/h-t-table", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    params: {},
    data: null,
    isStriped: false,
    headers: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', this.params);
      this.set('data', this.data);
      this.set('headers', this.headers);
      this.set('isStriped', this.isStriped);
      this.set('showButtons', this.showButtons != null ? this.showButtons : true);
      this.set('idTable', this.idTable != null ? this.idTable : "");
    },
    didInsertElement: function didInsertElement() {
      this.loadData();
    },
    didRender: function didRender() {// alert("OK");
      //$("#"+this.get( 'idTable')).DataTable();
    },
    loadData: function loadData() {
      var self = this;

      if (this.data) {
        this.data;
      } else if (String(this.metodo).length > 0 && String(this.clase).length > 0) {
        $.ajax({
          url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
          type: "post",
          data: this.get('params'),
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          async: false,
          success: function success(response) {
            if (response.success == true) {
              self.set('data', response.data);
            }

            self.set('data', response.data);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },
    actions: {
      load: function load() {
        this.load();
      },
      reload: function reload() {
        this.load();
      }
    }
  });

  _exports.default = _default;
});