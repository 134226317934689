define("comunidades-nafin/routes/nom-037/evidencias/politicas-de-riesgo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isLoading: false,
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set('isLoading', this.isSearching);
      controller.set('periodo_k', model.periodo_k);
      controller.set('url_pdf', this.url_pdf);
      setTimeout(function () {
        _this.cargarPDF();
      }, 1000);
    },
    cargarPDF: function cargarPDF() {
      var self = this;
      $.ajax({
        url: constant.APIURL + '/configuracion/getriskpolicy/nom037',
        type: "post",
        async: false,
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            var url = response.data.url_download;
            var extension = url.split(".")[url.split(".").length - 1];
            var extensiones = ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'bmp'];

            if (extensiones.includes(extension)) {
              $("#previewDocumentIFrame").attr("src", url);
              self.guardar_registro_de_visita();
            } else {
              showModal({
                titulo: 'No se puede previsualizr el documento.',
                mensaje: 'Solamente se puede previsualizar imagenes, documentos PDF. Te recomendamos descargar el documento para consultarlo.'
              });
            }
          } else {
            window.showModal({
              titulo: 'Error en sistema.',
              mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    guardar_registro_de_visita: function guardar_registro_de_visita() {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'nom037/politicaderiesgo/guardarregistrovisita',
        type: "post",
        async: false,
        data: {
          periodo_k: self.controller.get('periodo_k')
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success < '1') {
            window.showModal({
              titulo: 'Error en sistema.',
              mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    actions: {
      afterCreate: function afterCreate(dataObject) {},
      afterUpdate: function afterUpdate(dataObject) {},
      showCreateModal: function showCreateModal() {
        this.controller.set('updateStore', this.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));
        this.controller.set('createDeleteCommand', 'create');
        this.controller.set('isVisibleCreateUpdateForm', true);
        this.controller.set('isCommandUpdate', false);
        $('#button-save-changes').attr("disabled", "disabled");
        $("#politicasDeRiesgoK").val(null);
        $("#politicasDeRiesgoNombreDocumento").val('');
        $('#filesattached').html('');
        $('#createUpdateFormRiskPolicies').modal({});
      }
    }
  });

  _exports.default = _default;
});