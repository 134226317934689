define("comunidades-nafin/templates/components/nom-035-stps/reportes/cards/acontecimientos-traumaticos-severos-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVU+Ifzb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[6,\"h6\"],[10,\"class\",\"card-header mb-2 text-muted text-center\"],[10,\"id\",\"atsCard\"],[8],[0,\"\\n        Acontecimientos Traumaticos Severos \"],[6,\"br\"],[8],[9],[0,\"(Guía I)\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"card-body shadow\"],[10,\"style\",\"min-height:120px\"],[8],[0,\"\\n\\n        \\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n                \"],[6,\"div\"],[10,\"class\",\"text-center\"],[8],[0,\" \\n                    \"],[6,\"div\"],[10,\"class\",\"total\"],[8],[0,\"\\n                        \"],[6,\"div\"],[8],[1,[22,[\"data\",\"data\",\"aplicados\"]],false],[0,\" / \"],[1,[22,[\"data\",\"total\"]],false],[9],[0,\"\\n                        \"],[6,\"div\"],[10,\"class\",\"text-center total-small\"],[8],[0,\"aplicados\"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n\\n                \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n                    \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n                        \"],[6,\"span\"],[10,\"class\",\"ats-indicador ats-success py-2\"],[8],[1,[22,[\"data\",\"data\",\"sin_ats\",\"total\"]],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n                        \"],[6,\"span\"],[10,\"class\",\"ats-indicador ats-danger py-2\"],[8],[1,[22,[\"data\",\"data\",\"con_ats\",\"total\"]],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom-035-stps/reportes/cards/acontecimientos-traumaticos-severos-card.hbs"
    }
  });

  _exports.default = _default;
});