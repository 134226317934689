define("comunidades-nafin/components/nom-035-stps/reportes/reports/ats-generacion-masiva-evidencias-usuarios-ats", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Exportar y descargar las evidencias de seguimiento de usuarios que reportan ATS',
    clase: 'nom035stps/evidenciasats',
    metodo: 'listargeneracionmasiva',
    reporte: null,
    buttonGenerateZipIsVisible: true,
    periodo_k: null,
    headers: [{
      dataIndex: 'fecha_hora_inicio_generacion',
      title: 'Fecha y Hora'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'numero_documentos',
      title: 'Número documentos'
    }, {
      dataIndex: 'email_to',
      title: 'Email registrado'
    }, {
      dataIndex: 'download_path',
      title: 'Descargar'
    }],

    /**
     *
     **/
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('buttonGenerateZipIsVisible', this.buttonGenerateZipIsVisible);
      this.set('params', {
        'periodo_k': this.periodo_k
      });
      this.bindScopeToParent(this);
    },
    bindScopeToParent: function bindScopeToParent(_scope) {
      this.onBindScopeToParent(_scope);
    },
    propagationAfterCreateEvent: function propagationAfterCreateEvent() {
      this.loadData();
    },

    /**
     *
     */
    actions: {
      showCreateModal: function showCreateModal() {
        this.onShowFormRequestGenerateZip();
      }
    }
  });

  _exports.default = _default;
});