define("comunidades-nafin/routes/competencias/evaluacion-usuario/resultados", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isAppend: false,
    setupController: function setupController(controller) {
      var self = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        self.detallesEvaluacion();
      }, 1000);
    },
    // Detalles de evaluacion
    detallesEvaluacion: function detallesEvaluacion() {
      var self = this;
      /* localStorage.setItem("evaluacion_k",1); */

      var evaluacion_k = localStorage.getItem("evaluacion_k");
      $.ajax({
        url: constant.APIURL + 'e360/evaluacion360/detallesEvaluacion',
        type: "post",
        async: false,
        data: {
          evaluacion_k: evaluacion_k
        },
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success) {
            self.controller.set("nombre_evaluacion", response.data.evaluacion.nombre);
            self.controller.set("usuario", response.data.usuario); //self.controller.set("colaboradores",response.data.colaboradores);

            /* $("#ifr-mievaluacion").attr("src", window.constant.APIURL + "impresos/liderazgo/generarrespuestasusuario/" + evaluacion_k + "/" + response.data.usuario.usuario_k); */

            $("#ifr-mievaluacion").attr("src", window.constant.APIURL + "impresos/liderazgo/generarrespuestasusuario/" + evaluacion_k + "/" + response.data.usuario.usuario_k + "/" + response.data.usuario.nombre);

            if (response.data.usuario.length == 0) {
              $("#myTab li:first").addClass("d-none");
              $("#mi-evaluacion").removeClass("active");
              $("#miEvaluacion").removeClass("active");
              $("#miEvaluacion").removeClass("show");
              $("#miEvaluacion").addClass("d-none");
              $("#mis-colaboradores").addClass("active");
              $("#miEvaluacion").removeClass("d-none");
              $("#colaboradores").addClass("active");
              $("#colaboradores").addClass("show");
            }

            setTimeout(function () {
              $("#loading").fadeOut("slow");
              $('html, body').animate({
                scrollTop: $("body").offset().top
              }, 1000);
            }, 5000);
            var usuario_padre = Object.keys(response.data.colaboradores);
            $("#organigrama").append("<ul class='contiene-colaboradores'  style='list-style-type:none;padding:5px 0px'  id='ul-" + usuario_padre[0] + "'></ul>");
            self.colaboradores(response.data.colaboradores[usuario_padre[0]].colaboradores, usuario_padre[0]);
            if (Object.keys(response.data.colaboradores[usuario_padre[0]].colaboradores).length) $(".container-colaboradores").removeClass("d-none"); //self.controller.set("contiene_colaboradores",Object.keys(response.data.colaboradores[usuario_padre[0]].colaboradores).length);

            $("#organigrama > ul").find("ul").hide();
            $(".subcolaborador").click(function () {
              //$("#loading").show();
              $(".detalle-colaborador").removeClass("d-none");
              $('#ifr-colaborador').hide();
              $(".cargando-reporte").show();
              /* $("#ifr-colaborador").attr("src", window.constant.APIURL + "impresos/liderazgo/generarrespuestasusuario/" + evaluacion_k + "/" + $(this).data("usuario_k") ); */

              $("#ifr-colaborador").attr("src", window.constant.APIURL + "impresos/liderazgo/generarrespuestasusuario/" + evaluacion_k + "/" + $(this).data("usuario_k") + "/" + self.capitalizarPalabras($(this).text()));
              $(".colaborador-seleccionado").removeClass("colaborador-seleccionado");
              $(this).closest("div").addClass("colaborador-seleccionado");
              self.controller.set("usuario_colaborador_nombre", $(this).text());
              self.controller.set("usuario_colaborador_foto", $(this).data("foto"));
              self.controller.set("usuario_colaborador_puesto", $(this).data("puesto"));
              self.controller.set("usuario_colaborador_valorEnEvaluacion", $(this).data("resultado_obtenido"));
              self.controller.set("usuario_colaborador_porcentajeEnEvaluacion", $(this).data("porcentaje_obtenido"));
              setTimeout(function () {//$("#loading").fadeOut("slow");
              }, 2000);
            });
            $(".ver-colaboradores").click(function () {
              if ($(this).find("i").hasClass("fa-caret-right")) {
                $(this).addClass("is-open");
                $(this).find("i").removeClass("fa-caret-right").addClass("fa-caret-down");
                $(this).closest("li").find("ul:first").show();
              } else {
                $(this).removeClass("is-open");
                $(this).find("i").removeClass("fa-caret-down").addClass("fa-caret-right");
                $(this).closest("li").find("ul:first").hide();
              }
            });
            $(".lista-colaboradores").click(function () {
              if ($("#organigrama").hasClass("mostrar")) {
                $(".menu-colaboradores").hide();
                $(".detalles-colaborador-seleccionado").removeClass("col-lg-8");
                $(".mostrar-lista").removeClass("d-none");
                $("#organigrama").addClass("d-none").removeClass("mostrar");
              } else {
                $(".menu-colaboradores").show();
                $(".detalles-colaborador-seleccionado").addClass("col-lg-8");
                $(".mostrar-lista").addClass("d-none");
                $("#organigrama").removeClass("d-none").addClass("mostrar");
              }
            });
            $(".buscar-colaborador").click(function () {
              $("#organigrama").addClass("d-none");
              $(".cerrar-buscar-colaborador").removeClass("d-none");
              $("#resultados_busqueda").removeClass("d-none");
              $(".colaborador-seleccionado").removeClass("colaborador-seleccionado");
              $("#resultados_busqueda ul").html("");
              $("#resultados_busqueda h4").text("No se encontraron coincidencias");
              $("#buscador-colaborador").val($("#buscador-colaborador").val().toLocaleLowerCase());
              $(".detalles-colaborador:contains('" + $("#buscador-colaborador").val() + "')").each(function () {
                console.log($(this));
                var e = $(this);
                $("#resultados_busqueda h4").text("Resultados de búsqueda:");
                $("#resultados_busqueda ul").append("<li class='card container colaborador-resultado-busqueda' style='text-transform:capitalize'><div class='row'><div class='col-10'>" + $(this).text() + "</div><div class='col-2 text-right'><i class='fa fa-eye '></i></div></li>");
                $(".colaborador-resultado-busqueda").off().click(function () {
                  $(".buscar-colaborador").val("");
                  $(".cerrar-buscar-colaborador").click();
                  $(".is-open").click(); //$(".ver-colaboradores").click();

                  $(".subcolaborador-" + e.data("usuario_k")).click(); //self.abrirListaPadre($(".subcolaborador-" + e.data("usuario_k")));

                  $(".li-colaborador").each(function () {
                    if ($(this).find(".colaborador-seleccionado")) {
                      if ($(this).find(".colaborador-seleccionado").text() != "") {
                        console.log($(this), $(this).children(".ver-colaboradores"));
                        $(this).find(".ver-colaboradores:first").click();
                      }
                    }
                  });
                  $(".colaborador-seleccionado .ver-colaboradores ").click();
                  $("#buscador-colaborador").val("");
                  /*                                $("#organigrama > ul > li").each(function(){
                                                      let a = $(this);
                                                      if(a.children("li").find(".is-open")){
                                                          a.find(".ver-colaboradores:first").click();
                                                      }
                                                  })*/
                });
              }); //addClass("colaborador-seleccionado");
            });
            $(".cerrar-buscar-colaborador").click(function () {
              $("#buscador-colaborador").val("");
              $("#organigrama").removeClass("d-none");
              $("#resultados_busqueda").addClass("d-none");
              $(this).addClass("d-none");
            });
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    abrirListaPadre: function abrirListaPadre(nodo) {
      var self = this;
      var padre = nodo.closest("li").parent(".contiene-colaboradores");
      var abuelo = padre.closest("li").parent(".contiene-colaboradores");

      if (abuelo.length) {
        padre.find(".ver-colaboradores").click();
        if (abuelo.find(".is-open")) abuelo.find(".ver-colaboradores").click(); //abuelo.siblings(".ver-colaboradores").click();

        self.abrirListaPadre(padre);
      } //if(padre.length)
      //abuelo.find(".ver-colaboradores").click();

    },
    colaboradores: function colaboradores(nodo, ul) {
      var self = this;
      $.each(nodo, function (usuario_k_padre, padre) {
        console.log(padre.colaboradores);
        if (Object.keys(padre.colaboradores).length) $("#ul-" + ul).append("<li id='li-" + usuario_k_padre + "' style='text-transform:capitalize' class='pointer  li-colaborador'><div class='jefe detalles-colaborador' data-usuario_k='" + padre.detalles.usuario_k + "' style='padding:0px'><span class=' px-1 ver-colaboradores' style='display:inline-block;width:20px'><i class='fa fa-caret-right'></i></span><span data-puesto=' " + padre.detalles.puesto + "' data-resultado_obtenido='" + padre.detalles.resultado_obtenido + "' data-porcentaje_obtenido='" + padre.detalles.resultado_porcentaje + "' data-usuario_k='" + padre.detalles.usuario_k + "' data-foto='" + padre.detalles.foto + "'   class='subcolaborador px-1 subcolaborador-" + padre.detalles.usuario_k + "'>" + padre.detalles.nombre.toLowerCase() + "</span></div><ul class='contiene-colaboradores'  style='list-style-type:none;padding:0px 0px;border-left:4px groove gray;margin-left:8px'  id='ul-" + usuario_k_padre + "'></ul></li>");else $("#ul-" + ul).append("<li id='li-" + usuario_k_padre + "' style='text-transform:capitalize' class='pointer li-colaborador'><div class='detalles-colaborador' data-usuario_k='" + padre.detalles.usuario_k + "' style='padding:0px'><span class=' px-1' style='display:inline-block;width:20px;border-bottom:groove 4px gray'><i class=''></i></span><span class='subcolaborador px-1 subcolaborador-" + padre.detalles.usuario_k + "' data-puesto=' " + padre.detalles.puesto + "' data-porcentaje_obtenido='" + padre.detalles.resultado_porcentaje + "' data-resultado_obtenido='" + padre.detalles.resultado_obtenido + "' data-foto='" + padre.detalles.foto + "'  data-usuario_k='" + padre.detalles.usuario_k + "'>" + padre.detalles.nombre.toLowerCase() + "</span></div><ul  style='list-style-type:none;padding:0px 0px;border-left:4px groove gray;margin-left:8px' id='ul-" + usuario_k_padre + "'></ul></li>");
        self.colaboradores(padre.colaboradores, usuario_k_padre);
      });
    },
    capitalizarPalabras: function capitalizarPalabras(val) {
      return val.toLowerCase().trim().split(' ').map(function (v) {
        return v[0].toUpperCase() + v.substr(1);
      }).join(' ');
    }
  });

  _exports.default = _default;
});