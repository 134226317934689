define("comunidades-nafin/routes/general/usuarios/datos-demograficos/captura-demograficos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isWorking: false,
    urlEvaluacion: "",
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('instanciaConfiguracion', window.instanciaConfiguracion);
      controller.set('isWorking', this.isWorking);
      controller.set('descripciones', "");
      localStorage.setItem("logout_after_complete", false);
      this.loadData(controller);
    },
    loadData: function loadData(controller) {
      var demograficos = []; // Carga de los Demograficos

      $.ajax({
        url: constant.APIURL + 'usuario/listarDemograficos',
        xhrFields: {
          withCredentials: true
        },
        type: "get",
        dataType: "json",
        success: function success(r) {
          controller.set('descripciones', r.descripciones);
          console.log(r.descripciones);
          $.each(r.data, function (index, value) {
            if (demograficos[value.orden_categoria] === undefined) {
              demograficos[value.orden_categoria] = {
                demografico_k: value.demografico_k,
                demografico: value.demografico,
                valores: []
              };
            } //if(demograficos[value.demografico_k] === undefined)


            demograficos[value.orden_categoria]["valores"].push(value);
          });
          controller.set("demograficos", demograficos.filter(Boolean));
          controller.set('urlEvaluacion', r.descripciones.url_evaluacion);
          localStorage.setItem("logout_after_complete", r.descripciones.clima_cerrar_sesion);
          localStorage.setItem("mensaje_final_evaluacion", r.descripciones.clima_mensaje_final_evaluacion);
        },
        error: function error(e) {}
      }); // Carga de instrucciones para las evaluaciones

      var welcome_message = '';
      welcome_message = window.translate("comunidades.comunidad_especifica.msg.msg_mensaje_bienvenida_comunidad.valor", '¡Bienvenid@ @userData.nombre@!');

      if (welcome_message.trim() != '') {
        welcome_message = welcome_message.replace('@userData.nombre@', this.session.userData.nombre);
      }

      var evaluacion_titulo = "<p><b>" + welcome_message + "</b></p>",
          evaluacion_introduccion = "<p>Como parte de nuestros procesos, hemos implementado una evaluación de clima laboral, la cual nos permitirá conocer elementos para mejorar como empresa.</p>" + "<p>Has sido seleccionado como evaluador de algunos colaboradores, por lo cual te pedimos que leas atentamente cada una de las categorías y competencias.<br />Te pedimos que contestes con honestidad de acuerdo a las condiciones en las que actualmente laboras, tu participación es muy importante. </p>"; // Datos por default 

      controller.set('evaluacion_titulo', evaluacion_titulo);
      controller.set('evaluacion_introduccion', evaluacion_introduccion);
      $.ajax({
        url: constant.APIURL + 'usuario/evaluacionIntroduccion',
        type: "post",
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.numFilas == '1') {
            var aux_titulo = response.data[0].clima_evaluacion_introduccion_titulo; //aux_titulo     = self.session != undefined ? aux_titulo.replace( '@nombre_usuario@', self.session.userData.nombre ):aux_titulo.replace( '@nombre_usuario@', "");

            controller.set('evaluacion_titulo', aux_titulo);
            controller.set('evaluacion_introduccion', response.data[0].clima_evaluacion_introduccion);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      save: function save() {
        //this.controller.set( 'isWorking', true );
        var self = this;
        var parametros_capturados = {};
        var numero_elementos_visibles = 0;
        var numero_elementos_capturados = 0;
        var error = false;
        /*
         * Recolectamos los datos capturados por usuario.
         */

        $('select.options-demografico').each(function () {
          if ($(this).val() == "") {
            error = true;
            $(this).focus();
            return false;
          }

          parametros_capturados[$(this).val()] = $(this).find("option:selected").data("valor");
        });

        if (error) {
          //showModal( { titulo: 'DAtos incompletos.', mensaje : 'Captura todos los elementos del formulario' } );
          //self.controller.set( 'isWorking', false);
          return;
        } else {
          $.ajax({
            url: constant.APIURL + 'usuario/editardemograficos',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            data: parametros_capturados,
            success: function success(r) {
              var respons = JSON.parse(r);
              self.controller.set('isWorking', false);
              document.location.href = self.controller.get('urlEvaluacion');
            },
            error: function error(e) {
              self.controller.set('isWorking', false);
              window.showModalError();
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});