define("comunidades-nafin/components/nom037/capacitacion/request-form-massive-capacitaciones-certificados-de-usuarios", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      get = _Ember.get,
      set = _Ember.set;

  var _default = Ember.Component.extend({
    periodo_k: null,
    isWorking: false,
    isFinished: false,
    hasError: false,
    command: 'create',
    errorMessage: '',
    self: void 0,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('isWorking', this.isWorking);
      this.set('isFinished', this.isFinished);
      this.set('hasError', this.hasError);
      this.set('command', this.command);
      this.set('errorMessage', this.errorMessage);
      this.set('periodo_k', this.periodo_k);
    },
    _submit: function _submit(recordToUpdate, CI_controller, CI_method, success_callback_function) {
      /*
       * @todo: for now  can´t posible use recordToUpdate.toJSON(). The returned values are not updated (not correct).
       */
      var self = this;
      this.set('isWorking', true);
      /**
       * [YURAM] BEGIN
       *
       *  #Hack para recuperar datos del formulario.
       */

      if (Ember.$("#inputEmail").val() == '') {
        this.set('isWorking', false);
        this.set('isFinished', false);
        this.set('hasError', true);
        this.set('errorMessage', 'Es requerido un email para notificar la descarga.');
        return;
      }

      var recordToUpdateObject = {
        activo: 1,
        compreso_k: Ember.$("#inputCompresoK").val(),
        email: Ember.$('#inputEmail').val(),
        periodo_k: this.get('periodo_k')
      };
      /**
       * [YURAM] END
       *
       */

      Ember.$.ajax({
        url: constant.APIURL + '/' + CI_controller + '/' + CI_method,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: recordToUpdateObject,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var response = JSON.parse(response_string);

          if (response.success) {
            self.set('isWorking', false);
            self.set('isFinished', true);
            self.set('hasError', false);

            if (self.get('command') == 'update') {
              if (self.onAfterUpdate) {
                self.onAfterUpdate(response.data);
              }
            } else {
              if (self.onAfterCreate) {
                self.onAfterCreate(response.data);
              }
            }

            if (success_callback_function) {
              success_callback_function();
            }

            self.send('hideModal');
          } else {
            if (response.msg || response.error) {
              /*
               * El servidor nos devuelve un error
               */
              self.set('isWorking', false);
              self.set('isFinished', false);
              self.set('hasError', true);
              self.set('errorMessage', response.msg ? response.msg : response.error);
            } else {
              self.set('isWorking', false);
              self.set('isFinished', true);
              self.set('hasError', true);
            }
          }
        },
        error: function error(_error) {
          /*
           * no se pudo eliminar el registro....
           */
          self.set('isWorking', false);
          self.set('isFinished', true);
          self.set('hasError', true);
        }
      });
    },
    actions: {
      showModal: function showModal() {
        this.set('isVisibleCreateUpdateForm', true);

        if (this.get('command') == 'update') {
          this.set('isCommandUpdate', true);
        } else {
          this.set('isCommandUpdate ', false);
        }

        this.set('isWorking', false);
        this.set('isFinished', false);
        this.set('hasError', false);
        this.set('errorMessage', '');
      },
      hideModal: function hideModal() {
        this.set('command', this.command);
        this.set('isCommandUpdate', false);
        this.set('isWorking', false);
        this.set('isFinished', false);
        this.set('hasError', false);
        this.set('errorMessage', '');
        this.set('isVisibleCreateUpdateForm', false);
        Ember.$('#requestFormMassiveUserReports').modal('hide');
      },
      submit: function submit(storeRecord) {
        var record = storeRecord;

        if (this.get('command') == 'update') {
          this._submit(record, 'nom037/capacitaciones', 'requestzip', function () {
            console.dir(record);
          });
        } else {
          this._submit(record, 'nom037/capacitaciones', 'requestzip', function () {
            console.dir(record);
          });
        }
      }
    }
  });

  _exports.default = _default;
});