define("comunidades-nafin/initializers/authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Session = Ember.Object.extend({
    isAuthenticated: false,
    isAdmin: false,
    isTutor: false,
    userData: {}
  }); // ROLES
  // Human Talent Admin					1
  // Administrador Nafin					2
  // Administrador Nafin Diagnostico		3
  // Administrador Nafin Notificaciones	4
  // Alumnos Nafin 						5
  // Tutor Nafin							6
  // Alumnos								7

  function initialize(application) {
    application.register('session:main', Session);
    application.inject('controller', 'session', 'session:main');
    application.inject('component', 'session', 'session:main');
    application.inject('route', 'session', 'session:main'); //let self = this._super(...arguments);

    var aux_secciones = [];
    var secciones = window.location.href.split("#");
    var subsecciones = '';
    var token = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    var band_token = '';

    if (token != '') {
      band_token = token.substring(0, 3);

      if (band_token == 'TK-') {
        aux_secciones = secciones[1].split("/");
        secciones[1] = "/" + aux_secciones[1];
      } else {
        token = ''; // Solo enviamos la clave si es valida.
      }
    }

    window.errors = {};
    window.accesoPublico = false;

    if (secciones[1] != null && secciones[1] == window.constant.URL_CLIMA_ORGANIZACIONAL) {
      window.accesoPublico = true;
      Ember.$.ajax({
        url: window.constant.APIURL + 'publico/usuariopublicctr/accesopublico',
        async: false,
        contentType: 'application/x-www-form-urlencoded',
        dataType: "json",
        type: "post",
        data: {
          "hostname": location.hostname,
          "token": token
        },
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          console.log(r);

          if (r.success) {
            if (r.configuracion.url_redireccion_login) {
              document.location = r.configuracion.url_redireccion_login; //secciones[0];				
            } else {
              document.location = secciones[0];
            }
          } else {
            window.showModal({
              titulo: 'Aviso',
              mensaje: r.data.error
            });
            window.stop();
          }
        },
        error: function error(e) {
          Ember.$("#btn-login").attr("disabled", false);
          console.error(e);
        }
      });
      return false;
    } else if (secciones[1] != null) {
      subsecciones = secciones[1].split("/");

      if (subsecciones[1] == 'nom-037' && subsecciones[2] == 'evidencias_galeria') {
        var usuario_k = subsecciones[3];
        var periodo_k = subsecciones[4];
        window.accesoPublico = true;
        Ember.$.ajax({
          url: window.constant.APIURL + 'publico/usuariopublicctr/accesopublico_evidencia',
          async: false,
          contentType: 'application/x-www-form-urlencoded',
          dataType: "json",
          type: "post",
          data: {
            "hostname": window.location.hostname
          },
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            if (r.success) {
              if (r.configuracion.url_redireccion_login) {
                document.location = r.configuracion.url_redireccion_login + usuario_k + "/" + periodo_k;
              } else {
                document.location = secciones[0];
              }
            } else {
              window.showModal({
                titulo: 'Aviso',
                mensaje: r.data.error
              });
              window.stop();
            }
          },
          error: function error(e) {
            console.error(e);
          }
        });
        return false;
      }
    } else {
      Ember.$(document).ajaxComplete(function (e, r) {
        //console.log( "Triggered ajaxComplete handler.",response );
        //statusText: "Internal Server Error"
        if (r.status == 500) {
          Ember.$('#modal-avisos .modal-header').text('Error');
          Ember.$('#modal-avisos .modal-body').text("No se ha podido establecer la conexión por favor intenta mas tarde");
          Ember.$('#modal-avisos').modal('show');
        }

        var response = {};

        try {
          response = JSON.parse(r.responseText);
        } catch (ex) {//console.log(ex);
        }

        if (response.fatal) {
          if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
            setTimeout(function () {
              var secciones = window.location.href.split("#");

              if (!window.constant.APPURLSOFFLINE.includes(secciones[1]) || secciones.length == 1) {
                Ember.$("#modal_login").modal('show');
                Ember.$('#loading').hide();
                Ember.$(".modal-backdrop.show").css("opacity", "1");
                Ember.$(".modal-backdrop").addClass("backgroundLogin");
                Ember.$(".modal").addClass("backgroundLogin");
                Ember.$("#logoLogin").attr("src", Ember.$("#logoHeader").attr("src"));
              }
            }, 5000);
          } else {
            Ember.$('#modal-avisos').modal('show');
            Ember.$('.modal-backdrop').css({
              'opacity': '1'
            });
            Ember.$(".modal").addClass("backgroundLogin");
            Ember.$('#modal-avisos .modal-body').html('No has iniciado sesión o esta ya ha finalizado, deberá iniciar sesión desde el entorno público en el menú "Iniciar Sesión"');
            Ember.$("#modal-avisos").on("hidden.bs.modal", function () {//window.location.href = window.constant.LANDINGURL;
            });
          }
        }
      });
    }

    Ember.$(document).ready(function () {
      //5 minutos para cada interaccion
      var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1; // Detects if device is in standalone mode

      var isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches; //() => ('standalone' in window.navigator) && (window.navigator.standalone);

      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); // Checks if should display install popup notification:
      //alert(isInStandaloneMode + " " + iOS + " " + isSafari);
      //if (iOS && !isInStandaloneMode && isSafari) {

      if (isInStandaloneMode) {
        var intervalID = window.setInterval(obtenerMensajes, 1000 * 60 * 5);
        setTimeout(function () {
          obtenerMensajes();
        }, 10000);

        function obtenerMensajes() {
          Ember.$.ajax({
            url: window.constant.APIURL + "notificaciones/listarnotificacionessinleer",
            type: "post",
            data: {
              tipo: 4
            },
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            dataType: "json",
            success: function success(response) {
              if (response.success == true) {
                var body = JSON.parse(response.data.notificacion);
                toastr.options.closeButton = true;

                toastr.options.onclick = function () {
                  window.location.href = "/cursos";
                };

                toastr.info(body.body, '', {
                  timeOut: 10000
                });
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        }
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});