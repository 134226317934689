define("comunidades-nafin/components/competencias/evaluacion-usuario/aplicacion/competencia-card", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    competenciaRecord: {},
    onClick: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('competenciaRecord', this.competenciaRecord);
    },
    didInsertElement: function didInsertElement() {},
    actions: {
      onClick: function onClick() {
        if (typeof this.onClick == 'function') {
          this.onClick();
        }
      }
    }
  });

  _exports.default = _default;
});