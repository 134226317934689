define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/acciones-internas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    reporteGeneracionMasivaAccionesInternasScope: null,
    clase: 'nom035stps/accionesinternas',
    metodo: 'listarevidencias',
    model: function model(controller) {
      this.set('start', 0);
      var data_array = [];
      var start = this.get('start');
      var self = this;
      $('#btn-load-more-elements').attr('disabled', 'disabled');
      $.ajax({
        url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
        type: 'POST',
        sync: true,
        xhrFields: {
          withCredentials: true
        },
        data: 'start=' + start,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var responseObject = JSON.parse(response_string);
          responseObject.data.forEach(function (data) {
            data.evidencia_ruta_documento = constant.BASEURL + data.evidencia_ruta_documento;
            data_array.pushObject(self.store.createRecord('nom-035-stps/acciones-internas/evidencia', data));
          });

          if (responseObject.data.length > 0) {
            self.set('start', start + constant.PAGESIZE);
          }

          $('#btn-load-more-elements').removeAttr('disabled');
        },
        error: function error(_error) {
          $('#btn-load-more-elements').removeAttr('disabled');
        }
      });
      return data_array;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('periodo_k', model.periodo_k);
    },
    onLoadMore: function onLoadMore() {
      var self = this;
      var start = this.get('start');
      $('#btn-load-more-elements').attr('disabled', 'disabled');
      this.controller.set('isLoading', true);
      $.ajax({
        url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'start=' + start,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_text) {
          var response = $.parseJSON(response_text);

          if (response.success) {
            response.data.forEach(function (recordObject) {
              var is_record_in_store = false;
              self.controller.model.toArray().forEach(function (item) {
                if (item.get('evidencia_k') == recordObject.evidencia_k) {
                  is_record_in_store = true;
                }
              });

              if (!is_record_in_store) {
                var element = self.store.createRecord('nom-035-stps/acciones-internas/evidencia', recordObject);
                self.controller.model.pushObject(element);
              }
            });

            if (response.data.length > 0) {
              self.set('start', start + constant.PAGESIZE);
              $('#btn-load-more-elements').removeAttr('disabled');
            } else {
              $('#btn-load-more-elements').text('Ups! No hay mas elementos');
            }
          } else {
            $('#btn-load-more-elements').removeAttr('disabled');
          }

          self.controller.set('isLoading', false);
        },
        error: function error(_error2) {
          $('#btn-load-more-elements').removeAttr('disabled');
          self.controller.set('isLoading', false);
        }
      });
    },
    actions: {
      loadMore: function loadMore() {
        this.onLoadMore();
      },
      showFormRequestGenerateZip: function showFormRequestGenerateZip(record) {
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */
        $("#compresoK").val(null);
        $("#inputEmail").val('');
        $('#requestFormMassiveUserReports').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      bindScopeToReportAtsGeneracionMasivaAccionesInternasComponent: function bindScopeToReportAtsGeneracionMasivaAccionesInternasComponent(scope_reporte_ats_generacion_masiva_reportes) {
        this.reporteGeneracionMasivaAccionesInternasScope = scope_reporte_ats_generacion_masiva_reportes;
      },
      afterCreate: function afterCreate() {
        if (this.reporteGeneracionMasivaAccionesInternasScope) {
          if (isFunction(this.reporteGeneracionMasivaAccionesInternasScope.propagationAfterCreateEvent)) {
            this.reporteGeneracionMasivaAccionesInternasScope.propagationAfterCreateEvent();
          }
        }
      },
      afterUpdate: function afterUpdate() {},
      showPreviewModal: function showPreviewModal(record) {
        var evidencia_ruta_documento = record.get ? record.get('evidencia_ruta_documento') : record.evidencia_ruta_documento;
        /* var url                         = constant.BASEURL + evidencia_ruta_documento; */

        var url = evidencia_ruta_documento;
        var extension = url.split(".")[url.split(".").length - 1];
        var extensiones_google = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
        var extensiones_navegador = ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'bmp'];

        if (extensiones_navegador.includes(extension)) {
          $("#previewDocumentIFrame").attr("src", url);
          $('#previewDocumentModal').modal({});
        } else if (extensiones_google.includes(extension)) {
          $("#previewDocumentIFrame").attr("src", "https://docs.google.com/viewer?url=" + url + "&embedded=true");
          $('#previewDocumentModal').modal({});
        } else {
          /* Forzar descarga del documento */
          var myWindow = window.open(url, "_blank");
        }
      }
    }
  });

  _exports.default = _default;
});