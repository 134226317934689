define("comunidades-nafin/routes/nom-037/dashboard", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Estatus de los cuestionarios por trabajador',
    clase: 'nom037/administrador',
    metodo: 'audienciaResumenPorTrabajador',
    reporte: 'nom035/export/audienciaReporteResumenPorTrabajador',
    periodo_k: null,
    params: {},
    usuario_k: 0,
    direccion_visita_k: 0,
    id_colaborador_direccion_k: 0,
    datatableName: "colaboradoresTable",
    deleteAddress: null,
    _controller: null,
    headers: [{
      dataIndex: 'url_reporte_pdf',
      title: 'Reporte'
    }, {
      dataIndex: 'datos_trabajador',
      title: 'Datos de Trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de Trabajo'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'fecha_ultima_actualizacion',
      title: 'Ultima Actualización'
    }, {
      dataIndex: 'evaluacion',
      title: 'Lista de verificación'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.resetNewEvent(this);
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        $("#loading").fadeOut(1500); //self.loadComboData(controller);

        self.loadData(controller);
        controller.set('title', self.get("title"));
        controller.set('headersTable', self.get("headers"));
        controller.set('datatableName', self.get("datatableName"));
        controller.set('reporteExcelColaboradores', window.constant.APIURL + "nom037/administrador/reporteExcelColaboradores");
        controller.set("direcciones", []);
        controller.set("colaborador_header", "");
        controller.set("domicilio_visita", "");
        controller.set("id_colaborador_direccion_k", "");
        controller.set("url_google_maps", "");
        controller.set("domicilio", "");
      }, 1000);
    },
    loadComboData: function loadComboData(controller) {
      var self = this;
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/listarcentrosdetrabajo',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('inputGroupSelectCentrosDeTrabajoData', response.data);
            controller.inputGroupSelectCentrosDeTrabajoData = response.data;
          }

          console.log(controller.get('inputGroupSelectCentrosDeTrabajoData'));
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    loadData: function loadData(controller) {
      var self = this;

      self._controller.send("fullcalendar");

      if (String(self.get("metodo")).length > 0 && String(self.get("clase")).length > 0) {
        $.ajax({
          url: constant.APIURL + String(self.get("clase")) + '/' + String(self.get("metodo")),
          type: "post",
          data: {
            params: self.params,
            start: self.start
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              controller.set('dataTable', response.data);
              controller.set('total_records', response.numFilas);

              if (self.start + self.pageSize > self.total_records) {
                self.set('buttonNextIsDisabled', true);
              } else {
                self.set('buttonNextIsDisabled', false);
              }

              setTimeout(function () {
                $("#" + self.get('datatableName')).DataTable({
                  language: {
                    "decimal": "",
                    "emptyTable": "Sin registros",
                    "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
                    "infoEmpty": "Mosrando 0 to 0 de 0 registros",
                    "infoFiltered": "(Filtro _MAX_ registros totales)",
                    "infoPostFix": "",
                    "thousands": ",",
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "loadingRecords": "Cargando...",
                    "processing": "",
                    "search": "Buscar:",
                    "zeroRecords": "Sin coincidencias",
                    "paginate": {
                      "first": "Primero",
                      "last": "Último",
                      "next": "Siguiente",
                      "previous": "Anterior"
                    },
                    "aria": {
                      "sortAscending": ": activate to sort column ascending",
                      "sortDescending": ": activate to sort column descending"
                    }
                  },
                  initComplete: function initComplete() {
                    this.api().columns().every(function () {
                      var column = this;
                      var select = $('<select><option value=""></option></select>').appendTo($(column.header())).on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                      });
                      column.data().unique().sort().each(function (d, j) {
                        select.append('<option value="' + d + '">' + d + '</option>');
                      });
                    });
                  }
                }); // Eliminamos los header de busqueda de las 2 primeras columnas

                $("#colaboradoresTable thead tr th:eq(0)").find("select").remove();
                $("#colaboradoresTable thead tr th:eq(1)").find("select").remove(); // Evento para mostrar/editar domicilios

                $("#" + self.get('datatableName')).on("click", ".domicilios", function () {
                  controller.usuario_k = $(this).data("usuario_k");
                  self.send("showAddress", $(this), controller);
                });
              }, 2000);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },
    actions: {
      filterAndReload: function filterAndReload() {
        var self = this;
        var params = {
          estatus: $('#inputGroupSelectEstatus').val(),
          centro_trabajo_k: $('#inputGroupSelectCentroTrabajoK').val()
        };
        $("#" + this.get("datatableName")).DataTable().destroy();
        setTimeout(function () {
          self._controller.set('dataTable', []);

          Ember.set();
        }, 2000); //this.set("params",JSON.stringify(params));

        this.set("params", params); //this.setParamsCentroTrabajoK( $('#inputGroupSelectCentroTrabajoK').val() );
        //this.setParamsEstatus( $('#inputGroupSelectEstatus').val() );
        //this.setParamsUsuario( $('#inputGroupTextUsuario').val() );
        //this.loadData(this._controller);
      },
      showAddress: function showAddress(e, controller) {
        $("#direcciones").removeClass("d-none");
        $(".colaboradores").addClass("d-none");
        var target_offset = $("#direcciones").parent().parent().offset();
        var target_top = target_offset.top;
        $('html, body').animate({
          scrollTop: target_top
        }, 500, 'easeInSine');
        controller.set("colaborador_header", e.data("nombre"));
        $.ajax({
          url: constant.APIURL + "nom037/administrador/direccionesUsuario",
          type: "post",
          data: {
            usuario_k: e.data("usuariok")
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              controller.set("direcciones", response.data);
              controller.set("usuario_k", e.data("usuariok"));
            }
          }
        });
      },
      loadMap: function loadMap() {
        $("#table-direcciones").addClass("d-none");
        $("#mapa").removeClass("d-none");
        var mapOptions,
            map,
            marker,
            searchBox,
            city,
            infoWindow = '',
            addressEl = document.querySelector('#map-search'),
            latEl = document.querySelector('.latitude'),
            longEl = document.querySelector('.longitude'),
            element = document.getElementById('map-canvas');
        city = document.querySelector('.reg-input-city');
        mapOptions = {
          // How far the maps zooms in.
          zoom: 9,
          // Current Lat and Long position of the pin/
          center: new google.maps.LatLng(19.4136491, -99.1033026),
          // center : {
          // 	lat: -34.397,
          // 	lng: 150.644
          // },
          disableDefaultUI: false,
          // Disables the controls like zoom control on the map if set to true
          scrollWheel: true,
          // If set to false disables the scrolling on the map.
          draggable: true // If set to false , you cannot move the map around.
          // mapTypeId: google.maps.MapTypeId.HYBRID, // If set to HYBRID its between sat and ROADMAP, Can be set to SATELLITE as well.
          // maxZoom: 11, // Wont allow you to zoom more than this
          // minZoom: 9  // Wont allow you to go more up.

        };
        /**
         * Creates the map using google function google.maps.Map() by passing the id of canvas and
         * mapOptions object that we just created above as its parameters.
         *
         */
        // Create an object map with the constructor function Map()

        map = new google.maps.Map(element, mapOptions); // Till this like of code it loads up the map.

        /**
         * Creates the marker on the map
         *
         */

        marker = new google.maps.Marker({
          position: mapOptions.center,
          map: map,
          // icon: 'http://pngimages.net/sites/default/files/google-maps-png-image-70164.png',
          draggable: true
        });
        /**
         * Creates a search box
         */

        searchBox = new google.maps.places.SearchBox(addressEl);
        /**
         * When the place is changed on search box, it takes the marker to the searched location.
         */

        google.maps.event.addListener(searchBox, 'places_changed', function () {
          var places = searchBox.getPlaces(),
              bounds = new google.maps.LatLngBounds(),
              i,
              place,
              lat,
              long,
              resultArray,
              addresss = places[0].formatted_address;

          for (i = 0; place = places[i]; i++) {
            bounds.extend(place.geometry.location);
            marker.setPosition(place.geometry.location); // Set marker position new.
          }

          map.fitBounds(bounds); // Fit to the bound

          map.setZoom(15); // This function sets the zoom to 15, meaning zooms to level 15.
          // console.log( map.getZoom() );

          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          latEl.value = lat;
          longEl.value = long;
          resultArray = places[0].address_components; // Get the city and set the city input value to the one selected

          for (var i = 0; i < resultArray.length; i++) {
            if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
              citi = resultArray[i].long_name;
              city.value = citi;
            }
          } // Closes the previous info window if it already exists


          if (infoWindow) {
            infoWindow.close();
          }
          /**
           * Creates the info Window at the top of the marker
           */


          infoWindow = new google.maps.InfoWindow({
            content: addresss
          });
          infoWindow.open(map, marker);
        });
        /**
         * Finds the new position of the marker when the marker is dragged.
         */

        google.maps.event.addListener(marker, "dragend", function (event) {
          var lat, long, address, resultArray, citi;
          console.log('i am dragged');
          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            latLng: marker.getPosition()
          }, function (result, status) {
            if ('OK' === status) {
              // This line can also be written like if ( status == google.maps.GeocoderStatus.OK ) {
              address = result[0].formatted_address;
              resultArray = result[0].address_components; // Get the city and set the city input value to the one selected

              for (var i = 0; i < resultArray.length; i++) {
                if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
                  citi = resultArray[i].long_name;
                  console.log(citi);
                  city.value = citi;
                }
              }

              addressEl.value = address;
              latEl.value = lat;
              longEl.value = long;
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            } // Closes the previous info window if it already exists


            if (infoWindow) {
              infoWindow.close();
            }
            /**
             * Creates the info Window at the top of the marker
             */


            infoWindow = new google.maps.InfoWindow({
              content: address
            });
            infoWindow.open(map, marker);
          });
        });
      },
      cancelNewAddress: function cancelNewAddress() {
        $("#table-direcciones").removeClass("d-none");
        $("#mapa").addClass("d-none");
        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
      },
      saveAddress: function saveAddress() {
        var self = this;
        var data = {
          "usuario_k": $("#mapa #usuario_k").val(),
          "domicilio": $("#map-search").val(),
          "longitud": $("#mapa #longitude").val(),
          "latitud": $("#mapa #latitude").val(),
          "url_google_maps": "https://www.google.com.mx/maps/place/" + $("#map-search").val() + "/@" + $("#mapa #longitude").val() + "," + $("#mapa #latitude").val()
        };
        $.ajax({
          url: constant.APIURL + "nom037/administrador/guardarDireccion",
          type: "post",
          data: data,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#map-search").val('');
              $("#mapa #longitude").val('');
              $("#mapa #latitude").val('');

              self._controller.set("direcciones", response.data);

              self.send("cancelNewAddress");
            }
          }
        });
      },
      showList: function showList() {
        var self = this;
        $("#direcciones").addClass("d-none");
        $(".colaboradores").removeClass("d-none");
        $("#calendar-container").html("").html("<div id='calendar'></div>");

        self._controller.send("fullcalendar");

        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
      },
      agendar: function agendar(d) {
        var self = this;
        console.log(d);

        self._controller.set("domicilio_visita", d.domicilio);

        self._controller.set("id_colaborador_direccion_k", d.id_colaborador_direccion_k);

        self._controller.set("url_google_maps", d.url_google_maps);

        self._controller.set("domicilio", d.domicilio);

        $("#visita_modal").modal("show");
        $('#dtpicker').datetimepicker({
          locale: 'es',
          format: 'YYYY-MM-DD HH:mm',
          ignoreReadonly: true
        });
      },
      save: function save() {
        var self = this;
        var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        var error = false; // Valid name

        if ($("#nombre_visitante").val() == "" || $("#nombre_visitante").val().length <= 3) {
          $("#nombre_visitante").focus();
          error = true;
          return false;
        } // Valid email


        if (!regex.test($("#email_visitante").val()) || $("#email_visitante").val() == "") {
          $("#email_visitante").focus();
          error = true;
          return false;
        } // Valid date


        if ($("#fecha_hora_visita").val() == "") {
          $("#opendtpicker").click();
          error = true;
          return false;
        }

        if (!error) {
          var data = {
            "direccion_visita_k": 0,
            "id_colaborador_direccion_k": $("#id_colaborador_direccion_k").val(),
            "nombre_visitante": $("#nombre_visitante").val(),
            "nombre_colaborador": $("#nombre_colaborador").val(),
            "email_visitante": $("#email_visitante").val(),
            "telefono_visitante": $("#telefono_visitante").val(),
            "fecha_hora_visita": $("#fecha_hora_visita").val(),
            "domicilio": $("#domicilio").val(),
            "url_google_maps": $("#url_google_maps").val(),
            "usuario_k": self._controller.usuario_k
          };
          var sfulldate = $("#fecha_hora_visita").val().split(" ");
          var sdate = sfulldate[0].split("-");
          var shour = sfulldate[1].split(":"); //start: new Date(y, m, d, 10, 30),

          var start = new Date(sdate[0], sdate[1] - 1, sdate[2], shour[0], shour[1]);
          var obj = {
            title: "Visita a " + $("#nombre_colaborador").val(),
            start: start,
            allDay: false,
            className: 'important',
            data: data
          };
          console.log("=====");
          console.log(obj);
          $('#calendar').fullCalendar('renderEvent', obj, true);
          console.log("=====");
          $("#visita_modal").modal("hide");
          $("#loading").fadeIn(1500);
          $.ajax({
            url: constant.APIURL + "nom037/administrador/agendarVisita",
            type: "post",
            data: data,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            dataType: "json",
            success: function success(response) {
              $("#loading").fadeOut(1500);

              if (response.success == true) {
                self._controller.set("direcciones", response.data);
              }
            },
            error: function error(e) {
              $("#loading").fadeOut(1500);
              console.log(e);
            }
          });
        }
      },
      isEmail: function isEmail(email) {
        return;
      },
      fullcalendar: function fullcalendar() {
        var self = this;
        $.ajax({
          url: constant.APIURL + "nom037/administrador/visitas",
          type: "get",
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          async: false,
          success: function success(response) {
            var events = [];

            if (response.success == true) {
              $.each(response.data, function (i, v) {
                var sfulldate = v.fecha_hora_visita.split(" ");
                var sdate = sfulldate[0].split("-");
                var shour = sfulldate[1].split(":"); //start: new Date(y, m, d, 10, 30),

                var start = new Date(sdate[0], sdate[1] - 1, sdate[2], shour[0], shour[1]);
                var obj = {
                  title: v.colaborador,
                  start: start,
                  allDay: false,
                  className: v.estatus == "Validado" ? 'bg-success bgcalendar pointer' : 'bg-info bgcalendar',
                  data: v
                };
                events.push(obj);
              });
              console.log(events);
              /* initialize the calendar
              -----------------------------------------------------------------*/

              var date = new Date();
              var d = date.getDate();
              var m = date.getMonth();
              var y = date.getFullYear();
              /*  className colors
              
              className: default(transparent), important(red), chill(pink), success(green), info(blue)
              
              */

              /* initialize the external events
              -----------------------------------------------------------------*/

              $('#external-events div.external-event').each(function () {
                // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
                // it doesn't need to have a start or end
                var eventObject = {
                  title: $.trim($(this).text()) // use the element's text as the event title

                }; // store the Event Object in the DOM element so we can get to it later

                $(this).data('eventObject', eventObject); // make the event draggable using jQuery UI

                $(this).draggable({
                  zIndex: 999,
                  revert: true,
                  // will cause the event to go back to its
                  revertDuration: 0 //  original position after the drag

                });
              });
              /* initialize the calendar
              -----------------------------------------------------------------*/

              var calendar = $('#calendar').fullCalendar({
                header: {
                  left: 'title',
                  center: 'month',
                  right: 'prev,next today'
                },
                height: 250,
                editable: false,
                firstDay: 1,
                //  1(Monday) this can be changed to 0(Sunday) for the USA system
                selectable: true,
                defaultView: 'month',
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
                dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                buttonText: {
                  prev: "<span class='fc-text-arrow'>‹</span>",
                  next: "<span class='fc-text-arrow'>›</span>",
                  prevYear: "<span class='fc-text-arrow'>«</span>",
                  nextYear: "<span class='fc-text-arrow'>»</span>",
                  today: 'Hoy',
                  month: 'Mes',
                  week: 'Semana',
                  day: 'Dia'
                },
                axisFormat: 'h:mm',
                columnFormat: {
                  month: 'ddd',
                  // Mon
                  week: 'ddd d',
                  // Mon 7
                  day: 'dddd M/d',
                  // Monday 9/7
                  agendaDay: 'dddd d'
                },
                titleFormat: {
                  month: 'MMMM yyyy',
                  // September 2009
                  week: "MMMM yyyy",
                  // September 2009
                  day: 'MMMM yyyy' // Tuesday, Sep 8, 2009

                },
                allDaySlot: false,
                selectHelper: false,
                eventClick: function eventClick(info) {
                  self.send("visitDetails", info.data);
                },
                viewRender: function viewRender(view, element) {
                  setTimeout(function () {
                    $(".bgcalendar").removeClass("fc-event").removeClass("fc-event-hori").removeClass("fc-event-start").removeClass("fc-event-end");
                    $(".grafica-colaboradores").css({
                      'height': $("#calendar-container").height() + 'px'
                    });
                  }, 100);
                },
                _select: function _select(start, end, allDay) {
                  var title = prompt('Event Title:');

                  if (title) {
                    calendar.fullCalendar('renderEvent', {
                      title: title,
                      start: start,
                      end: end,
                      allDay: allDay
                    }, true // make the event "stick"
                    );
                  }

                  calendar.fullCalendar('unselect');
                },
                droppable: false,
                // this allows things to be dropped onto the calendar !!!
                drop: function drop(date, allDay) {
                  // this function is called when something is dropped
                  // retrieve the dropped element's stored Event Object
                  var originalEventObject = $(this).data('eventObject'); // we need to copy it, so that multiple events don't have a reference to the same object

                  var copiedEventObject = $.extend({}, originalEventObject); // assign it the date that was reported

                  copiedEventObject.start = date;
                  copiedEventObject.allDay = allDay; // render the event on the calendar
                  // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)

                  $('#calendar').fullCalendar('renderEvent', copiedEventObject, true); // is the "remove after drop" checkbox checked?

                  if ($('#drop-remove').is(':checked')) {
                    // if so, remove the element from the "Draggable Events" list
                    $(this).remove();
                  }
                },
                events: events
              });
            }

            $(".bgcalendar").removeClass("fc-event").removeClass("fc-event-hori").removeClass("fc-event-start").removeClass("fc-event-end");
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      visitDetails: function visitDetails(info) {
        var self = this;
        $("#visitValid").show();
        if (!info.direccion_visita_k) $("#visitValid").hide();
        $("#visitCancel").removeClass("d-none");
        $("#visitValid").removeClass("d-none");

        if (info.estatus == "Validado") {
          $("#visitValid").addClass("d-none");
          $("#visitCancel").addClass("d-none");
        }

        self._controller.direccion_visita_k = info.direccion_visita_k;
        self._controller.id_colaborador_direccion_k = info.id_colaborador_direccion_k;
        $(".nombre_colaborador").text(info.colaborador);
        $(".domicilio_visita").text(info.domicilio);
        $(".url_google_maps").attr("href", info.url_google_maps).text(info.url_google_maps);
        $(".nombre_visitante").text(info.nombre_visitante);
        $(".email_visitante").text(info.email_visitante);
        $(".telefono_visitante").text(info.telefono_visitante);
        $(".fecha_hora_visita").text(info.fecha_hora_visita);
        $("#detalles_modal").modal("show");
      },
      visitCancel: function visitCancel() {
        var self = this;
        $.ajax({
          url: constant.APIURL + 'nom037/administrador/cancelarVisita',
          type: "post",
          data: {
            direccion_visita_k: self._controller.direccion_visita_k,
            id_colaborador_direccion_k: self._controller.id_colaborador_direccion_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#calendar-container").html("").html("<div id='calendar'></div>");

              self._controller.send("fullcalendar");

              self._controller.set("direcciones", response.data);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      valid: function valid() {
        var self = this;
        $.ajax({
          url: constant.APIURL + 'nom037/administrador/validarDireccion',
          type: "post",
          data: {
            direccion_visita_k: self._controller.direccion_visita_k,
            id_colaborador_direccion_k: self._controller.id_colaborador_direccion_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#calendar-container").html("").html("<div id='calendar'></div>");

              self._controller.send("fullcalendar");

              self._controller.set("direcciones", response.data);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      uploadEvidences: function uploadEvidences(d) {
        $("#loading").fadeIn(500);
        $("#evidencias_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $.ajax({
          url: constant.APIURL + "nom037/administrador/listarEvidencias",
          type: "post",
          data: {
            id_colaborador_direccion_k: d.id_colaborador_direccion_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              localStorage.setItem("prevEvidences", JSON.stringify(response.data));
              $("#evidencias_modal").modal("show");
            }
          }
        });
        return false;
      },
      deleteAddress: function deleteAddress(d) {
        var self = this;
        self._controller.deleteAddress = d;
        $("#confirm_delete_modal").modal("show");
      },
      confirmDeleteAddress: function confirmDeleteAddress() {
        var self = this;
        var evidencia_k = $(this).data("evidencia_k");
        $.ajax({
          url: constant.APIURL + "nom037/administrador/eliminarDireccion",
          type: "post",
          data: {
            id_colaborador_direccion_k: self._controller.deleteAddress.id_colaborador_direccion_k,
            direccion_visita_k: self._controller.deleteAddress.direccion_visita_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              self._controller.set("direcciones", response.data);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});