define("comunidades-nafin/router", ["exports", "comunidades-nafin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // RUTAS PARA USUARIOS
    this.route('nom035/reportes', {
      path: '/administracion/reportes'
    });
    this.route('inicio', {
      path: '/'
    }); //this.route('inicio');

    this.route('comunidades');
    this.route('comunidades-movil');
    this.route('comunidades-offline');
    this.route('cursos-offline');
    this.route('ruta-offline');
    this.route('scorm-offline');
    this.route('registro');
    this.route('comunidades/comunidad-especifica', {
      path: '/comunidades/:comunidadid'
    });
    this.route('cursos');
    this.route('cursos/curso-especifico', {
      path: '/cursos/:cursoid'
    });
    this.route('diagnostico-empresarial');
    this.route('diagnostico-empresarial', {
      path: '/diagnostico-empresarial/:mis_diagnosticos'
    });
    this.route('webinars');
    this.route('noticias');
    this.route('eventos');
    this.route('foros');
    this.route('foros/foro-especifico', {
      path: '/foros/:foroid'
    });
    this.route('mediateca');
    this.route('consultas'); //this.route('consultas/consulta-especifica', { path: '/consultas/:consultaid' });

    this.route('consultas/consulta-especifica', {
      path: '/consultas/consulta-especifica'
    });
    this.route('directorio');
    this.route('resultados-busqueda');
    this.route('notificaciones');
    this.route('logros');
    this.route('perfil');
    this.route('cambiar-contrasenia');
    this.route('buscador'); // RUTAS PARA ADMINISTRADORES

    this.route('dashboard');
    this.route('catalogo-cursos');
    this.route('catalogo-evaluaciones');
    this.route('vista-previa-evaluacion');
    this.route('catalogo-evaluaciones-especifico');
    this.route('catalogo-certificado');
    this.route('catalogo-encuestas-especifico');
    this.route('rutas-aprendizaje-especifico', function () {
      this.route('rutas-objetivo');
      this.route('rutas-alumnos');
      this.route('rutas-agrupamiento');
      this.route('rutas-documentos');
    });
    this.route('administracion-comunidades');
    this.route('administracion-logros');
    this.route('administracion-diccionario');
    this.route('administracion-usuarios');
    this.route('nueva-comunidad');
    this.route('comunidad-detalle');
    this.route('repositorio-preguntas');
    this.route('ruta-apredizaje');
    this.route('catalogo-encuestas');
    this.route('respuestas-predefinidas');
    this.route('tipos-de-respuesta');
    this.route('usuarios');
    this.route('reportes');
    this.route('examples'); //this.route('contactos');

    this.route('historico', {
      path: '/historico/:historicoid'
    });
    this.route('historico');
    this.route('preguntas-diagnostico');
    this.route('preguntas-diagnostico', {
      path: '/preguntas-diagnostico/:preguntasdiagnosticoid'
    });
    this.route('preguntas-new');
    this.route('preguntas-new', {
      path: '/preguntas-new/:preguntasdiagnosticoid'
    });
    this.route('aviso-privacidad');
    this.route('admin-nuevousuario');
    this.route('experiments', function () {
      this.route('testing-area');
    });
    this.route('administracion-cursos');
    this.route('administracion-cursos-curso-especifico', {
      path: '/administracion-cursos/:cursoid'
    });
    this.route('recurso-no-disponible');
    this.route('faqs');
    this.route('human-talent', function () {
      this.route('sandbox');
    });
    this.route('notificacion', function () {
      this.route('envio-general');
    });
    this.route('nom-035-stps', function () {
      this.route('reportes', function () {
        this.route('administrador', function () {
          this.route('audiencia');
          this.route('acontecimientos-traumaticos-severos');
          this.route('entorno-organizacional');
          this.route('factores-riesgo-psicosocial');
        });
      });
      this.route('acontecimientos-traumaticos-severos', function () {
        this.route('administrador', function () {
          this.route('seguimiento');
          this.route('evidencias', function () {
            this.route('bienvenida', {
              path: '/guias-aplicadas/:periodo_k'
            });
            this.route('guias-aplicadas', {
              path: '/guias-aplicadas/:periodo_k'
            });
            this.route('politicas-de-riesgo', {
              path: '/politicas-de-riesgo/:periodo_k'
            });
            this.route('distribucion-guias', {
              path: '/distribucion-guias/:periodo_k'
            });
            this.route('estatus-aplicacion-guias', {
              path: '/estatus-aplicacion-guias/:periodo_k'
            });
            this.route('colaboradores-con-ats', {
              path: '/colaboradores-con-ats/:periodo_k'
            });
            this.route('acciones-internas', {
              path: '/acciones-internas/:periodo_k'
            });
            this.route('guias-colaboradores-ats', {
              path: '/guias-colaboradores-ats/:periodo_k'
            });
            this.route('evidencias-usuarios-ats', {
              path: '/evidencias-usuarios-ats/:periodo_k'
            });
            this.route('capacitaciones-certificados', {
              path: '/capacitaciones-certificados/:periodo_k'
            });
            this.route('plan-de-trabajo', {
              path: '/plan-de-trabajo/:periodo_k'
            });
            this.route('resultados-guia-2', {
              path: '/resultados-guia-2/:periodo_k'
            });
            this.route('resultados-guia-3', {
              path: '/resultados-guia-3/:periodo_k'
            });
            this.route('estatus-de-capacitacion', {
              path: '/estatus-de-capacitacion/:periodo_k'
            });
            this.route('consultas', {
              path: '/consultas/:periodo_k'
            });
          });
          this.route('acciones-internas', function () {
            this.route('evidencias');
          });
          this.route('evidencias-bienvenida');
        });
        this.route('acciones-internas', function () {});
      });
    });
    this.route('higienizacion-covid19', function () {
      this.route('reportes', function () {
        this.route('administrador', function () {
          this.route('resultados-generales');
        });
      });
    });
    this.route('preguntas-test');
    this.route('comunidades_nafin', function () {
      this.route('app', function () {
        this.route('templates', function () {
          this.route('nom-035-stps', function () {
            this.route('acontecimientos-traumaticos-severos', function () {
              this.route('acciones-internas', function () {});
            });
          });
        });
      });
    });
    this.route('general', function () {
      this.route('usuarios', function () {
        this.route('datos-demograficos', function () {
          this.route('captura-demograficos');
        });
      });
    });
    this.route('competencias', function () {
      this.route('evaluacion-usuario', function () {
        this.route('aplicacion');
        this.route('aplicacion', {
          path: '/aplicacion/:evaluacion_k'
        });
        this.route('resultados');
      });
    });
    this.route('lms', function () {
      this.route('reportes', function () {
        this.route('seguimiento_individual');
      });
      this.route('administracion-usuarios');
    });
    this.route('nom-037', function () {
      this.route('dashboard');
      this.route('evidencias', function () {
        this.route('politicas-de-riesgo', {
          path: '/politicas-de-riesgo/:periodo_k'
        });
        this.route('politicas-de-riesgo-visitas', {
          path: '/politicas-de-riesgo-visitas/:periodo_k'
        });
        this.route('estatus-de-capacitacion', {
          path: '/estatus-de-capacitacion/:periodo_k'
        });
        this.route('consultas', {
          path: '/consultas/:periodo_k'
        });
        this.route('resguardo-de-equipo', {
          path: '/resguardo-de-equipo/:periodo_k'
        });
        this.route('programa-de-mantenimiento', {
          path: '/programa-de-mantenimiento/:periodo_k'
        });
        this.route('contrato-colectivo', {
          path: '/contrato-colectivo/:periodo_k'
        });
        this.route('resguardo-de-equipos', {
          path: '/resguardo-de-equipos/:periodo_k'
        });
      });
      this.route('evidencias_galeria', {
        path: '/evidencias_galeria/:usuario_k/:periodo_k'
      });
    });
  });
  Router.reopen({
    location: 'hash'
  });
  var _default = Router;
  _exports.default = _default;
});