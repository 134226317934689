define("comunidades-nafin/templates/human-talent/sandbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D3Jk5HE7",
    "block": "{\"symbols\":[\"elemento\",\"indice_elemento\"],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[6,\"h1\"],[8],[0,\"Humant talent Sandbox\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-1\"],[8],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-10\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[22,[\"lista_elementos\"]]],null,{\"statements\":[[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"row mt-4 mb-4\"],[8],[0,\"\\n\\n\\n                \"],[6,\"div\"],[10,\"class\",\"col-2 text-end\"],[8],[0,\"\\n                    \"],[1,[20,\"competencias/evaluacion-usuario/aplicacion/template-evaluado\"],false],[0,\"\\n                \"],[9],[0,\"\\n\\n                \"],[6,\"div\"],[10,\"class\",\"col-8 text-center\"],[8],[0,\"\\n                    \"],[1,[26,\"star-rating\",null,[[\"width\",\"height\",\"fillColor\",\"useHalfStars\",\"rating\",\"onClick\"],[100,100,\"blue\",false,[21,1,[\"rating\"]],[26,\"route-action\",[\"onClickRating\",[21,2,[]]],null]]]],false],[0,\"\\n                \"],[9],[0,\"\\n\\n                \"],[6,\"div\"],[10,\"class\",\"col-2 text-start\"],[8],[0,\"\\n                    \"],[1,[20,\"competencias/evaluacion-usuario/aplicacion/template-evaluado\"],false],[0,\"\\n                \"],[9],[0,\"\\n\\n\\n            \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-1\"],[8],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[1,[20,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\"\\n\"],[1,[20,\"sub-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/human-talent/sandbox.hbs"
    }
  });

  _exports.default = _default;
});