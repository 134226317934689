define("comunidades-nafin/components/admin-communities/community-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    accesosArr: null,
    // init() {
    //   this.set('detailOn', false);
    // },
    start: 0,
    limit: 10,
    increment: 10,
    pendientes: true,
    normalizaComunidades: function normalizaComunidades(comunidades) {
      var _this = this;

      var comunidadesArr = [];

      var normalizeAccess = function normalizeAccess(acc) {
        var accessName = acc;

        if (_this.accesosArr) {
          _this.accesosArr.forEach(function (access) {
            if (access.tipo_acceso_k === acc) {
              accessName = access.nombre.split(' ', 1);
            }
          });
        }

        return accessName;
      };

      comunidades.forEach(function (comm) {
        if (comm.img.split('/')[0] === 'uploads') {
          comm.img = constant.BASEURL + comm.img;
        }

        var comunidad = {
          imagen: comm.img,
          nombre: comm.nombre,
          acceso: normalizeAccess(comm.tipo_acceso),
          estatus: comm.comunidad_activa === '0' ? 'Inactiva' : 'Activa',
          miembros: comm.numero_miembros,
          cursos: comm.numero_cursos,
          tutores: comm.numero_tutores,
          fecha: comm.timestamp_modificacion ? comm.timestamp_modificacion : comm.timestamp_modificacion,
          whole: comm,
          comunidad_k: comm.comunidad_k
        };
        comunidadesArr.push(comunidad);
      });

      var sortCommunities = function sortCommunities(a, b) {
        return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
      };

      this.set('communities', comunidadesArr.sort(sortCommunities));
      /* setTimeout(function(){
       	let hash = $( "#comunidadesContainer .comunidad-item").eq(6).attr("id"); 	
        window.location.hash = hash;
       },1000);*/
    },
    listarComunidades: function listarComunidades(data) {
      var self = this;
      var temporal = []; //for()
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      self.cargarComunidades(this.start, this.limit);
    },
    cargarComunidades: function cargarComunidades(_start, _limit) {
      var self = this;

      var fetchAccesos = function fetchAccesos(cb) {
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarTiposAcceso',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(_success) {
            var accesos = JSON.parse(_success);

            if (cb) {
              cb(accesos.data.data);
            }
          },
          error: function error(_error) {
            if (cb) {
              cb(false);
            }
          }
        });
      };

      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listar',
        method: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: {
          params: '{"nombre":""}',
          start: _start,
          limit: _limit
        },
        success: function success(_success2) {
          var communities = JSON.parse(_success2);
          fetchAccesos(function (success) {
            if (success) {
              self.accesosArr = success;
            }

            if (communities.data.length + 1 >= _limit) {
              self.set("mostrarBotonVerMas", true);
              self.pendientes = true;
              self.normalizaComunidades(communities.data);
            } else {
              self.pendientes = false;
              self.set("mostrarBotonVerMas", false);
              self.normalizaComunidades(communities.data);
            }
          });
        },
        error: function error(_error2) {
          throw _error2;
        }
      });
    },
    verMas: function verMas() {
      var self = this;

      if (self.pendientes) {
        self.limit += self.increment;
        self.cargarComunidades(self.start, self.limit);
      }
    },
    actions: {
      getCommunityDetail: function getCommunityDetail(desiredComm) {
        this.set('detailOn', true);
        this.set('commDetails', desiredComm);
      },
      searchCommunity: function searchCommunity() {
        var self = this;
        var searchTerm = Ember.$('#communityToSearch').val();
        console.log(searchTerm);
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listar',
          method: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"nombre":"' + searchTerm + '"}',
          success: function success(_success3) {
            var fetchAccesos = function fetchAccesos(cb) {
              Ember.$.ajax({
                url: constant.APIURL + 'comunidad/listarTiposAcceso',
                xhrFields: {
                  withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                success: function success(_success4) {
                  var accesos = JSON.parse(_success4);

                  if (cb) {
                    cb(accesos.data.data);
                  }
                },
                error: function error(_error3) {
                  if (cb) {
                    cb(false);
                  }
                }
              });
            };

            var communities = JSON.parse(_success3);
            fetchAccesos(function (success) {
              if (success) {
                self.accesosArr = success;
              }

              self.normalizaComunidades(communities.data);
            });
            /*let r = JSON.parse(response);
            var community = r.data;
            var html = '';
            self.normalizaComunidades(community);
            Object.keys(community).forEach((idx) => {
            	console.log(community)
            	html += '<div class="card bg_light_community box_shado">'
            	html += '<div class="card-body no_padding">'
            	html += '<div class="container-fluid">'
            	html += '<div class="row">'
            	html += '<div class="col-12 col-lg-4 col-xl-4 margin_ud_10px">'
            	html += '<img src="'+ community[idx].img +'" class="full_width" />'
            	html += '</div>'
            	html += '<br />'
            	html += '<div class="col-12 col-lg-8 col-xl-8">'
            	html += '<div class="row">'
            	html += '<div class="col-12 text-center">'
            	html += '<h2 class="padding_ud_10px">' + community[idx].nombre + '</h2>'
            	html += '</div>'
            	html += '</div>'
            	html += '<br>'
            	html += '<div class="row">'
            	html += '<div class="col-12 align-items-center text-center">'
            	html += '<div class="row">'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	if (community[idx].tipo_acceso == '4'){
            		html += '<p class="d-flex-row no_margin">Privado</p>'
            	}
            	else if (community[idx].tipo_acceso == '1'){
            		html += '<p class="d-flex-row no_margin">Público</p>'
            	}
            	html += '<p class="d-flex-row no_margin text_blue"><small>Acceso</small></p>'
            	html += '</div>'
            	html += '<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	if (community[idx].estatus == '0'){
            		html +=	'<p class="d-flex-row no_margin">Activa</p>'
            	}
            	else{
            		html +=	'<p class="d-flex-row no_margin">Inactiva</p>'
            	}
            	html += '<p class="d-flex-row no_margin text_blue"><small>Estatus</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">' + community[idx].numero_miembros + '</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Miembros</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">' + community[idx].numero_cursos + '</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Cursos</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-12 col-md-12 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">...</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Tutores</small></p>'
            	html += '</div>'
            	html +=	'</div>'
            	html += '</div>'
            	html += '</div>'
            	html +=	'</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '<br/>'
            	
            });*/

            Ember.$('#searchContainer').html(html);
          },
          error: function error(_error4) {
            console.log({
              error: _error4
            });
          }
        });
      },
      eliminarBusqueda: function eliminarBusqueda() {
        var self = this;
        Ember.$("#communityToSearch").val("");
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listar',
          method: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"nombre":""}',
          success: function success(_success5) {
            var fetchAccesos = function fetchAccesos(cb) {
              Ember.$.ajax({
                url: constant.APIURL + 'comunidad/listarTiposAcceso',
                xhrFields: {
                  withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                success: function success(_success6) {
                  var accesos = JSON.parse(_success6);

                  if (cb) {
                    cb(accesos.data.data);
                  }
                },
                error: function error(_error5) {
                  if (cb) {
                    cb(false);
                  }
                }
              });
            };

            var communities = JSON.parse(_success5);
            fetchAccesos(function (success) {
              if (success) {
                self.accesosArr = success;
              }

              self.normalizaComunidades(communities.data);
            });
            /*let r = JSON.parse(response);
            var community = r.data;
            var html = '';
            self.normalizaComunidades(community);
            Object.keys(community).forEach((idx) => {
            	console.log(community)
            	
            	html += '<div class="card bg_light_community box_shado" {{action "getCommunityDetail" community.whole}}>'
            	html += '<div class="card-body no_padding">'
            	html += '<div class="container-fluid">'
            	html += '<div class="row">'
            	html += '<div class="col-12 col-lg-4 col-xl-4 margin_ud_10px">'
            	html += '<img src="'+ community[idx].img +'" class="full_width" />'
            	html += '</div>'
            	html += '<br />'
            	html += '<div class="col-12 col-lg-8 col-xl-8">'
            	html += '<div class="row">'
            	html += '<div class="col-12 text-center">'
            	html += '<h2 class="padding_ud_10px">' + community[idx].nombre + '</h2>'
            	html += '</div>'
            	html += '</div>'
            	html += '<br>'
            	html += '<div class="row">'
            	html += '<div class="col-12 align-items-center text-center">'
            	html += '<div class="row">'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	if (community[idx].tipo_acceso == '4'){
            		html += '<p class="d-flex-row no_margin">Privado</p>'
            	}
            	else if (community[idx].tipo_acceso == '1'){
            		html += '<p class="d-flex-row no_margin">Público</p>'
            	}
            	html += '<p class="d-flex-row no_margin text_blue"><small>Acceso</small></p>'
            	html += '</div>'
            	html += '<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	if (community[idx].estatus == '0'){
            		html +=	'<p class="d-flex-row no_margin">Activa</p>'
            	}
            	else{
            		html +=	'<p class="d-flex-row no_margin">Inactiva</p>'
            	}
            	html += '<p class="d-flex-row no_margin text_blue"><small>Estatus</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">' + community[idx].numero_miembros + '</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Miembros</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-6 col-md-6 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">' + community[idx].numero_cursos + '</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Cursos</small></p>'
            	html +=	'</div>'
            	html +=	'<div class="col-12 col-md-12 col-lg-2 col-xl-2 p-2 m-lg-2 m-xl-2">'
            	html +=	'<p class="d-flex-row no_margin">...</p>'
            	html +=	'<p class="d-flex-row no_margin text_blue"><small>Tutores</small></p>'
            	html += '</div>'
            	html +=	'</div>'
            	html += '</div>'
            	html += '</div>'
            	html +=	'</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '</div>'
            	html += '<br/>'
            	
            });*/

            Ember.$('#searchContainer').html(html);
          },
          error: function error(_error6) {
            console.log({
              error: _error6
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});