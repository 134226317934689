define("comunidades-nafin/routes/nom-037/evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super(controller);

      var periodo_k = localStorage.getItem("periodo_nom037_k");
      /* Obtener PERIDO_K de la Empresa */

      controller.set('periodo_k', periodo_k);
      /* Funciones del menu lateral */

      setTimeout(function () {
        $(".elemento").click(function () {
          $(".arrowRight").addClass("d-none");
          $(".elemento").removeClass("active");
          $(".elemento").parent().removeClass("text-right");
          $(this).addClass("active");
          $(this).parent().addClass("text-right");
          $(this).find(".arrowRight").removeClass("d-none");
          $("#card-header-open").text($(this).text());
        });
        $("#opcion-evidencias").change(function () {
          window.location.href = $(this).find("option:selected").data("url");
        });

        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          $("#contenedorEvidencia").removeAttr("style");
        }
      }, 1000);
    }
  });

  _exports.default = _default;
});