define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/estatus-aplicacion-guias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SqWwI3au",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[10,\"style\",\"height: auto !important;\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"alert alert-info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n            \"],[1,[26,\"translate\",[\"nom_035_stps.carpeta_evidencias.msg.msg_descripcion_estatus_aplicacion_guias.valor\",\"En esta sección podra consultar el estatus de evaluación de cada usuario.\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion mt-2\"],[8],[0,\"\\n    \"],[1,[26,\"nom-035-stps/reportes/reports/ats-generacion-masiva-reportes-de-respuestas\",null,[[\"onShowFormRequestGenerateZip\",\"onBindScopeToParent\",\"periodo_k\"],[[26,\"route-action\",[\"showFormRequestGenerateZip\"],null],[26,\"route-action\",[\"bindScopeToReportAtsGeneracionMasivaReportesComponent\"],null],[22,[\"periodo_k\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion mt-2\"],[8],[0,\"\\n    \"],[1,[26,\"nom-035-stps/reportes/reports/audiencia-resumen-por-trabajador-reporte\",null,[[\"periodo_k\"],[[22,[\"periodo_k\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"nom-035-stps/acontecimientos-traumaticos-severos/request-form-massive-user-reports\",null,[[\"updateStore\",\"isVisibleCreateUpdateForm\",\"command\",\"isCommandUpdate\",\"onAfterCreate\",\"onAfterUpdate\",\"periodo_k\"],[[22,[\"updateStore\"]],[22,[\"isVisibleCreateUpdateForm\"]],[22,[\"createDeleteCommand\"]],[22,[\"isCommandUpdate\"]],[26,\"route-action\",[\"afterCreate\"],null],[26,\"route-action\",[\"afterUpdate\"],null],[22,[\"periodo_k\"]]]]],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/estatus-aplicacion-guias.hbs"
    }
  });

  _exports.default = _default;
});