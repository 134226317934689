define("comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/template-evaluacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "arI9S3PS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-4 col-sm-8 col-md-6 col-lg-2 mb-2 mt-2\"],[10,\"align\",\"center\"],[8],[0,\"\\n    \"],[6,\"img\"],[10,\"class\",\"img-fluid\"],[11,\"src\",[27,[[22,[\"evaluacionData\",\"ruta_imagen\"]]]]],[10,\"alt\",\"Evaluación de competencias\"],[10,\"style\",\"max-height:300px;height:100%\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-8 col-sm-8 col-md-6 col-lg-4 mb-1 mt-1 my-auto\"],[8],[0,\"\\n\\n    \"],[1,[26,\"ember-chart\",null,[[\"type\",\"data\",\"options\",\"width\",\"height\"],[\"doughnut\",[22,[\"avanceData\"]],[22,[\"chartoptions\"]],100,50]]],false],[0,\"\\n\\n  \"],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-12 col-sm-12 col-md-12 col-lg-12 mb-1 mt-1\"],[8],[0,\"\\n    \"],[1,[20,\"evaluacion_titulo\"],true],[0,\"\\n    \"],[1,[20,\"evaluacion_introduccion\"],true],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/template-evaluacion.hbs"
    }
  });

  _exports.default = _default;
});