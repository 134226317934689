define("comunidades-nafin/routes/nom-037/evidencias-galeria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('usuario_k', atob(model.usuario_k));
      controller.set('periodo_k', model.periodo_k);
      controller.set('logo', "lookandfeel/" + window.constant.THEME + "/img/logo.png");
      /* Cargamos las direcciones del usuario */

      self.cargardirecciones(controller);
    },
    cargardirecciones: function cargardirecciones(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'nom037/evidenciagaleria/listardirecciones',
        type: "POST",
        async: false,
        data: {
          usuario_k: controller.get("usuario_k"),
          periodo_k: controller.get("periodo_k")
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          setTimeout(function () {
            $("#loading").fadeOut(1500);
          }, 2000);
          controller.set('isVisibleBtnCerrarGaleria', true);

          if (response.numFilas == 0) {
            window.showModal({
              titulo: 'Evidencias.',
              mensaje: 'El usuario no cuenta con domicilio(s) cargados.'
            });
          } else if (response.numFilas == 1) {
            controller.set('isVisibleBtnCerrarGaleria', false); // En caso de tener una sola dirección pasar directo a las evidencias

            setTimeout(function () {
              self.cargarlistaevidencias(controller, response.data[0].id_colaborador_direccion_k);
            }, 1000);
          }

          controller.set('direcciones', response.data);
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    cargarlistaevidencias: function cargarlistaevidencias(controller, id_colaborador_direccion_k) {
      $.ajax({
        url: constant.APIURL + 'nom037/evidenciagaleria/listarevidencia',
        type: "POST",
        async: false,
        data: {
          usuario_k: controller.get("usuario_k"),
          periodo_k: controller.get("periodo_k"),
          id_colaborador_direccion_k: id_colaborador_direccion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var imagenes = [];
          controller.set('imagenes', imagenes);

          if (response.numFilas > 0) {
            $.each(response.data, function (ind, elem) {
              imagenes[ind] = {
                path_ruta: constant.BASEURL + elem.ruta
              };
            });
            controller.set('imagenes', imagenes);
            setTimeout(function () {
              var swiper = new Swiper(".mySwiper", {
                zoom: true,
                spaceBetween: 30,
                effect: "fade",
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false
                },
                pagination: {
                  el: ".swiper-pagination",
                  type: "progressbar"
                },
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev"
                }
              });
              $("#loading").fadeOut(1500);
            }, 1000);
            /* Mostrar evidencias */

            $("#warpper-direcciones").addClass("d-none");
            $("#warpper-imagenes").removeClass("d-none");
          } else {
            $("#loading").fadeOut(1500);
            /* Mostrar error en caso de que el domicilio no tenga evidencias */

            window.showModal({
              titulo: 'Evidencias.',
              mensaje: 'El domicilio no cuenta con evidencia cargada.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    actions: {
      obtenerevidencias: function obtenerevidencias() {
        var self = this;
        $("#loading").show();
        var id_colaborador_direccion_k = $("#cb_direcciones").val().trim();

        if (id_colaborador_direccion_k != "") {
          self.cargarlistaevidencias(self.controller, id_colaborador_direccion_k);
        } else {
          window.showModal({
            titulo: 'Evidencias.',
            mensaje: 'Selecciona un domicilio para mostrar las evidencias.'
          });
        }
      },
      cerrar_galeria: function cerrar_galeria() {
        /* Mostrar direcciones */
        $("#warpper-imagenes").addClass("d-none");
        $("#warpper-direcciones").removeClass("d-none");
      }
    }
  });

  _exports.default = _default;
});