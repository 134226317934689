define("comunidades-nafin/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller) {
      this.controller = controller;
      $.ajax({
        url: constant.APIURL + 'reportes/dashboard/getDashboardByRol',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'groupBy=categoria&groupDir=ASC',
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          var respons = JSON.parse(r),
              categos = [],
              actual,
              indice = -1; //armar el arreglo como lo requiere ember

          respons.data.forEach(function (element, index) {
            if (actual != element.categoria) {
              indice++;
              actual = element.categoria;
              categos.push({
                categoria_k: element.categoria_k,
                nombre: element.categoria,
                cuenta: 0,
                cuentaString: 'Registro',
                childrens: [],
                indice: indice
              });
            }

            categos[indice].childrens.push(element);
            categos[indice].cuenta++;
            categos[indice].cuentaString = categos[indice].cuenta == 1 ? 'Registro' : ' Registros';
          }); //console.log( categos );

          controller.set('categorias', categos);
        }
      });
      $('body').css({
        'overflow': 'hidden'
      });
      controller.set('show_log', false);

      if (window.accionesModulo.reportes_reporteador.ver_log_consultas_dashboard === true) {
        controller.set('show_log', true);
      }
    },
    cargarGraficas: function cargarGraficas(params) {
      params = params || 'cargar_dependencias=1';
      $.ajax({
        url: constant.APIURL + 'reportes/dashboard/loadnewdashboard/' + this.dash_k,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: params,
        contentType: 'application/x-www-form-urlencoded',
        success: function success(html) {
          //ar html = res.responseText;
          $('#espacioIframe').html('<iframe id="diviFrame" style="overflow:auto;width:100%;height:100%; background: white;" frameborder="0" ></iframe>');
          var iframe = document.getElementById('diviFrame');
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(html);
          iframe.contentWindow.document.close();
        }
      });
    },
    loadDashboardGoogleDataStudio: function loadDashboardGoogleDataStudio(params) {
      $('#espacioIframe').html('<iframe id="diviFrame" src="' + params.google_data_studio_url + '" style="overflow:auto;width:100%;height:100%; background: white;" frameborder="0" ></iframe>');
    },
    getFiltroObject: function getFiltroObject() {
      var unindexed_array = $('#filtrosdiv').serializeArray();
      var indexed_array = {};
      $.map(unindexed_array, function (n, i) {
        if (n['value'] != 'xxxx') indexed_array[n['name']] = n['value'];
      });
      return indexed_array;
    },
    actions: {
      mostrarLog: function mostrarLog() {
        $.ajax({
          url: constant.APIURL + 'reportes/dashboard/logConsultas',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          contentType: 'application/x-www-form-urlencoded',
          success: function success(r) {
            if (r) {
              var table = "<table id='log-table' class='table table-bordered table-striped'><thead><tr><th>Reporte</th><th>Usuario consultor</th><th>Fecha hora consulta</th></tr></thead><tbody>";
              $.each(r, function (i, v) {
                table += "<tr><td>" + v.nombre_reporte + "</td><td>" + v.usuario + "</td><td align='center'>" + v.fecha_hora_creacion + "</td></tr>";
                console.log(v);
              });
              table += "</tbody></table>";
              $('#espacioIframe').html(table);
              setTimeout(function () {
                $("#log-table").dataTable({
                  "language": {
                    "processing": "Procesando...",
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "zeroRecords": "No se encontraron resultados",
                    "emptyTable": "Ningún dato disponible en esta tabla",
                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "search": "Buscar:",
                    "infoThousands": ",",
                    "loadingRecords": "Cargando...",
                    "paginate": {
                      "first": "Primero",
                      "last": "Último",
                      "next": "Siguiente",
                      "previous": "Anterior"
                    },
                    "aria": {
                      "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                      "sortDescending": ": Activar para ordenar la columna de manera descendente"
                    },
                    "buttons": {
                      "copy": "Copiar",
                      "colvis": "Visibilidad",
                      "collection": "Colección",
                      "colvisRestore": "Restaurar visibilidad",
                      "copyKeys": "Presione ctrl o u2318 + C para copiar los datos de la tabla al portapapeles del sistema. <br \/> <br \/> Para cancelar, haga clic en este mensaje o presione escape.",
                      "copySuccess": {
                        "1": "Copiada 1 fila al portapapeles",
                        "_": "Copiadas %d fila al portapapeles"
                      },
                      "copyTitle": "Copiar al portapapeles",
                      "csv": "CSV",
                      "excel": "Excel",
                      "pageLength": {
                        "-1": "Mostrar todas las filas",
                        "_": "Mostrar %d filas"
                      },
                      "pdf": "PDF",
                      "print": "Imprimir"
                    },
                    "autoFill": {
                      "cancel": "Cancelar",
                      "fill": "Rellene todas las celdas con <i>%d<\/i>",
                      "fillHorizontal": "Rellenar celdas horizontalmente",
                      "fillVertical": "Rellenar celdas verticalmentemente"
                    },
                    "decimal": ",",
                    "searchBuilder": {
                      "add": "Añadir condición",
                      "button": {
                        "0": "Constructor de búsqueda",
                        "_": "Constructor de búsqueda (%d)"
                      },
                      "clearAll": "Borrar todo",
                      "condition": "Condición",
                      "conditions": {
                        "date": {
                          "after": "Despues",
                          "before": "Antes",
                          "between": "Entre",
                          "empty": "Vacío",
                          "equals": "Igual a",
                          "notBetween": "No entre",
                          "notEmpty": "No Vacio",
                          "not": "Diferente de"
                        },
                        "number": {
                          "between": "Entre",
                          "empty": "Vacio",
                          "equals": "Igual a",
                          "gt": "Mayor a",
                          "gte": "Mayor o igual a",
                          "lt": "Menor que",
                          "lte": "Menor o igual que",
                          "notBetween": "No entre",
                          "notEmpty": "No vacío",
                          "not": "Diferente de"
                        },
                        "string": {
                          "contains": "Contiene",
                          "empty": "Vacío",
                          "endsWith": "Termina en",
                          "equals": "Igual a",
                          "notEmpty": "No Vacio",
                          "startsWith": "Empieza con",
                          "not": "Diferente de"
                        },
                        "array": {
                          "not": "Diferente de",
                          "equals": "Igual",
                          "empty": "Vacío",
                          "contains": "Contiene",
                          "notEmpty": "No Vacío",
                          "without": "Sin"
                        }
                      },
                      "data": "Data",
                      "deleteTitle": "Eliminar regla de filtrado",
                      "leftTitle": "Criterios anulados",
                      "logicAnd": "Y",
                      "logicOr": "O",
                      "rightTitle": "Criterios de sangría",
                      "title": {
                        "0": "Constructor de búsqueda",
                        "_": "Constructor de búsqueda (%d)"
                      },
                      "value": "Valor"
                    },
                    "searchPanes": {
                      "clearMessage": "Borrar todo",
                      "collapse": {
                        "0": "Paneles de búsqueda",
                        "_": "Paneles de búsqueda (%d)"
                      },
                      "count": "{total}",
                      "countFiltered": "{shown} ({total})",
                      "emptyPanes": "Sin paneles de búsqueda",
                      "loadMessage": "Cargando paneles de búsqueda",
                      "title": "Filtros Activos - %d"
                    },
                    "select": {
                      "cells": {
                        "1": "1 celda seleccionada",
                        "_": "$d celdas seleccionadas"
                      },
                      "columns": {
                        "1": "1 columna seleccionada",
                        "_": "%d columnas seleccionadas"
                      },
                      "rows": {
                        "1": "1 fila seleccionada",
                        "_": "%d filas seleccionadas"
                      }
                    },
                    "thousands": ".",
                    "datetime": {
                      "previous": "Anterior",
                      "next": "Proximo",
                      "hours": "Horas",
                      "minutes": "Minutos",
                      "seconds": "Segundos",
                      "unknown": "-",
                      "amPm": ["AM", "PM"],
                      "months": {
                        "0": "Enero",
                        "1": "Febrero",
                        "10": "Noviembre",
                        "11": "Diciembre",
                        "2": "Marzo",
                        "3": "Abril",
                        "4": "Mayo",
                        "5": "Junio",
                        "6": "Julio",
                        "7": "Agosto",
                        "8": "Septiembre",
                        "9": "Octubre"
                      },
                      "weekdays": ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
                    },
                    "editor": {
                      "close": "Cerrar",
                      "create": {
                        "button": "Nuevo",
                        "title": "Crear Nuevo Registro",
                        "submit": "Crear"
                      },
                      "edit": {
                        "button": "Editar",
                        "title": "Editar Registro",
                        "submit": "Actualizar"
                      },
                      "remove": {
                        "button": "Eliminar",
                        "title": "Eliminar Registro",
                        "submit": "Eliminar",
                        "confirm": {
                          "_": "¿Está seguro que desea eliminar %d filas?",
                          "1": "¿Está seguro que desea eliminar 1 fila?"
                        }
                      },
                      "error": {
                        "system": "Ha ocurrido un error en el sistema (<a target=\"\\\" rel=\"\\ nofollow\" href=\"\\\">Más información&lt;\\\/a&gt;).<\/a>"
                      },
                      "multi": {
                        "title": "Múltiples Valores",
                        "info": "Los elementos seleccionados contienen diferentes valores para este registro. Para editar y establecer todos los elementos de este registro con el mismo valor, hacer click o tap aquí, de lo contrario conservarán sus valores individuales.",
                        "restore": "Deshacer Cambios",
                        "noMulti": "Este registro puede ser editado individualmente, pero no como parte de un grupo."
                      }
                    },
                    "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
                  },
                  "scrollY": "400px",
                  "scrollCollapse": true,
                  "paging": false,
                  "order": [[2, "desc"]]
                });
              }, 2000);
            }
          }
        });
      },
      buscarConFiltro: function buscarConFiltro() {
        $('#espacioIframe').html('<img src="/img/loading_spinner.gif">');
        var filtro = JSON.stringify(this.getFiltroObject());
        this.cargarGraficas('filtros=' + filtro + '&cargar_dependencias=1');
      },
      ocultarMenu: function ocultarMenu() {
        var menu_dashboard = $('#menu_dashboard'),
            reporte_dashboard = $('#reporte_dashboard'),
            muestra_menu = $('#muestra_menu').val();

        if (muestra_menu == '0') {
          $('#lbl_titulo_menu').html('Ocultar Reportes');
          menu_dashboard.css({
            'opacity': '1',
            'visibility': 'visible'
          });
          menu_dashboard.addClass('col-12 col-md-4 col-xl-3');
          $(".lbl_titulo_dashboard").removeClass('col-md-12');
          $(".lbl_titulo_dashboard").addClass('col-md-9');
          $('#muestra_menu').val('1');
        } else {
          $('#lbl_titulo_menu').html('Mostrar Reportes');
          menu_dashboard.css({
            'opacity': '0',
            'visibility': 'hidden'
          });
          menu_dashboard.removeClass('col-12 col-md-4 col-xl-3');
          reporte_dashboard.removeClass('col-12 col-md-8 col-xl-9');
          reporte_dashboard.addClass('col-12 col-md-12 col-xl-12');
          $(".lbl_titulo_dashboard").removeClass('col-md-9');
          $(".lbl_titulo_dashboard").addClass('col-md-12');
          $('#muestra_menu').val('0');
        }
      },
      mostrarReporte: function mostrarReporte(dashboardObject, categorias) {
        var reporte_k = dashboardObject.reporte_k;
        var dash_k = dashboardObject.dashboard_k;
        var origen_de_datos = dashboardObject.origen_de_datos;
        categorias.forEach(function (c) {
          c.childrens.forEach(function (r) {
            Ember.set(r, "selected", false);

            if (dash_k == r.dashboard_k) {
              Ember.set(r, "selected", true);
            }
          });
        }); //Tracking

        $.ajax({
          url: constant.APIURL + 'reportes/dashboard/insertarRegistroLog',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: {
            reporte_k: reporte_k,
            dashboard_k: dash_k,
            nombre_reporte: dashboardObject.nombre,
            url: origen_de_datos == 'GOOGLE-DATA-STUDIO' ? dashboardObject.google_data_studio_url : ""
          },
          success: function success(r) {
            console.log(r);
          }
        });

        if (origen_de_datos == 'GOOGLE-DATA-STUDIO') {
          $('#espacioIframe').html('<img src="/img/loading_spinner.gif">');
          this.loadDashboardGoogleDataStudio(dashboardObject);
        } else {
          var iframe = '';
          var self = this;
          self.dash_k = dash_k;
          $('#espacioIframe').html('<img src="/img/loading_spinner.gif">');
          $.ajax({
            url: constant.APIURL + 'reportes/reporteador/getReporte',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            data: 'reporte_k=' + reporte_k,
            success: function success(r) {
              var respons = JSON.parse(r);
              var num = 1;
              var html = '';
              var columnas = respons.columnas;
              var filtros = respons.filtros;
              var filtrosHtml = '';
              filtros.forEach(function (rec) {
                //para cada uno de los filtros
                filtrosHtml += '<div class="input-group col-12 col-lg-6 margin_ud_10px">';
                if (rec.xtype == 'textfield') filtrosHtml += '<input type="text" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" />';

                if (rec.xtype == 'combo') {
                  filtrosHtml += '<select id="filtro' + rec.filtro_k + '" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" /></select>';
                  $.ajax({
                    url: constant.APIURL + 'reportes/reporteador/getComboData',
                    type: 'POST',
                    xhrFields: {
                      withCredentials: true
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    data: 'reporte_k=' + rec.reporte_k + '&filtro_k=' + rec.filtro_k,
                    success: function success(r) {
                      var respons = JSON.parse(r);
                      var select = '<option value="xxxx">Seleccionar...</option>';
                      respons.data.forEach(function (val) {
                        select += '<option value="' + val.value + '">' + val.label + '</option>';
                      });
                      $('#filtro' + rec.filtro_k).append(select);
                    }
                  });
                }

                filtrosHtml += '</div>';
              });

              if (filtrosHtml != '') {
                $('#filtrosdiv').html(filtrosHtml);
                $('#btnBuscar').removeClass('hidden');
              } else {
                $('#filtrosdiv').html('');
                $('#btnBuscar').addClass('hidden');
              } //-- cargar datos


              self.cargarGraficas();
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});