define("comunidades-nafin/initializers/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function initialize(application) {
    window.idiomaGlobal = {};

    if (!window.accesoPublico) {
      Ember.$.ajax({
        url: constant.APIURL + '/configuracion/getlanguage',
        type: "post",
        async: false,
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            window.idiomaGlobal = response.data;
            window.idiomaPrincipal = response.idioma_principal;
          } else {
            if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
              console.log(!window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.');
            } else {
              window.showModal({
                titulo: 'Error en sistema.',
                mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
              });
            }
          }
        },
        error: function error(e) {
          // Sin conexión a internet
          if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {} // Con conexión a internet
          else {
              window.showModalError();
            }

          console.log(e);
        }
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});