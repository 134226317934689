define("comunidades-nafin/routes/historico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      goBack: function goBack() {
        var url_page = new URLSearchParams(window.location.search);

        if (document.referrer.search("/preguntas-diagnostico") > -1) {
          window.location.href = "/diagnostico-empresarial/0?tipo=misDiagnosticos";
        } else if (url_page.get('tipo') != null && document.referrer.search("tipo=misDiagnosticos") == -1) {
          window.location.href = document.referrer + "?tipo=misDiagnosticos";
        } else {
          history.back();
        }
      }
    }
  });

  _exports.default = _default;
});