define("comunidades-nafin/components/admin-components/noticias-nuevo-noticia", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      set = Ember.set;

  var _default = Ember.Component.extend({
    nombreFoto: '',
    imagenCrop: '',
    imagenCropX: '',
    imagenCropY: '',
    imagenCropWidth: '',
    imagenCropHeight: '',
    init: function init() {
      this._super.apply(this, arguments); //$('#myCropper').slim();

    },
    didRender: function didRender() {
      var self = this;
      self.crearCrop();
    },
    crearCrop: function crearCrop() {
      var self = this;
      var cropper = new Slim(document.getElementById('myCropper'), {
        ratio: '16:9',
        crop: {
          x: 0,
          y: 0,
          width: 100,
          height: 100
        },
        post: "input, output, actions",
        service: constant.APIURL + 'noticia/uploadImgNoticia',
        download: false,
        instantEdit: true,
        didRemove: function didRemove() {
          self.nombreFoto = '';
        },
        didCancel: function didCancel() {},
        willSave: function willSave(data, ready) {
          //Mostramos mensaje de carga para alertar al usuario
          Ember.$("#modalNoticia #agregarNoticia").css('display', 'none');
          Ember.$("#agregarNoticia").hide();
          ready(data);
        },
        didUpload: function didUpload(error, data, response) {
          if (error == null) {
            Ember.$("#modalNoticia #agregarNoticia").css('display', 'block');
            self.nombreFoto = response.pathOriginal;
            self.imagenCrop = response.path;
            self.imagenCropX = response.crop_x;
            self.imagenCropY = response.crop_y;
            self.imagenCropWidth = response.crop_width;
            self.imagenCropHeight = response.crop_height; //alert(this.nombreFoto);
          } else {}

          Ember.$("#agregarNoticia").show();
        },
        label: window.translate('noticias.noticias.lbl.lbl_arrastra_y_suelta_tu_imagen.valor', 'Arrastra y suelta tu imagen o selecciónala desde tu equipo'),
        buttonConfirmLabel: 'Aceptar',
        buttonCancelLabel: 'Cancelar',
        crossOrigin: true,
        withCredentials: true,
        meta: {
          withCredentials: true
        }
      });
    },
    actions: {
      cierraModalEditarNoticia: function cierraModalEditarNoticia(_noticia_k) {
        Ember.$("#modalNoticia #noticia").jqte();
      },
      clearModal: function clearModal() {
        Ember.$('#tipo_noticia').val("");
        Ember.$('#mostrar_en').val("");
        Ember.$('#titulo').val("");
        Ember.$('#tags').val("");
        Ember.$('#noticia').val("");
        Ember.$('[data-role="remove"]').click();
        Ember.$('#cont-imagen-noticia .texto-subio-imagen').css("display", "none");
        Ember.$('#cont-imagen-noticia #imagen_seleccionada').css("display", "none");
        Ember.$('.search-choice-close').click();
      },
      addNoticia: function addNoticia() {
        Ember.$('#loading-modal').show();
        Ember.$("#modalNoticia").modal({
          backdrop: "static",
          keyboard: false
        }); //$('#modalNoticia #imagen-cargando').removeClass('d-none');

        Ember.$("#modalNoticia #noticia").jqte();
        this.send('clearModal');
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/mostrarEn',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'start=0&limit=20&params={"bandera":"1"}',
          success: function success(r) {
            var respons = JSON.parse(r);
            console.log(respons);
            var html = '';
            Object.keys(respons.data).forEach(function (idx) {
              var selected = '';

              if (respons.data[idx].llave_k == '3') {
                selected = "selected='selected'";
              }

              html += '<option value="' + respons.data[idx].llave_k + '" ' + selected + '>' + respons.data[idx].nombre + '</option>';
            });
            Ember.$('#tipo_noticia').html(html);
            Ember.$('.cls-noticia-mostrar-en').css('display', 'block');
          },
          error: function error(e) {
            console.log(e);
          }
        });
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listar_comunidades_por_usuario',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'start=0&limit=200&params={"noticias_activo":"1"}',
          success: function success(r) {
            Ember.$('#loading-modal').hide();
            var respons = JSON.parse(r);

            var htmls = [],
                _htmls;

            Object.keys(respons.data).forEach(function (idx) {
              var html = '<option value="' + respons.data[idx].comunidad_k + '">' + respons.data[idx].nombre + '</option>';
              htmls.push(html);
            });
            _htmls = htmls.join(''); //console.log(_htmls);

            Ember.$('#mostrar_en')[0].innerHTML = _htmls;
            Ember.$('.form-control-chosen').chosen({
              allow_single_deselect: true,
              width: '100%'
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      onSelectTipoNoticia: function onSelectTipoNoticia(val) {
        if (val == 3) {
          Ember.$('.cls-noticia-mostrar-en').css('display', 'block');
        } else {
          Ember.$('.cls-noticia-mostrar-en').css('display', 'none');
        }
      },
      sendNoticia: function sendNoticia() {
        var self = this;
        var strMessage;
        var tipo_noticia_k = Ember.$('#tipo_noticia').val();
        var comunidad_k = Ember.$('#mostrar_en').val();
        var titulo = Ember.$('#titulo').val();
        var subtitulo = Ember.$('#subtitulo').val();
        var tags = Ember.$('#tags').val(); //noticia         = noticia.replace(/\\"/g,'"');
        //noticia         = noticia.replace(/["]/g,'\\"');
        //var imagen       	= 'uploads/noticias/noticias/' + this.nombreFoto;

        var imagen = self.nombreFoto;
        var imagen_crop = self.imagenCrop;
        var imagen_crop_x = self.imagenCropX;
        var imagen_crop_y = self.imagenCropY;
        var imagen_crop_width = self.imagenCropWidth;
        var imagen_crop_height = self.imagenCropHeight; //var imagen          = $('#imagen_seleccionada').attr('src');

        if (titulo == null || titulo.length == 0 || /^\s+$/.test(titulo)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_titulo.valor", "Por favor indique el título.");
          alert(strMessage);
          Ember.$("#modalNoticia #titulo").focus();
          return false;
        }

        if (subtitulo == null || subtitulo.length == 0 || /^\s+$/.test(subtitulo)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_subtitulo.valor", "Por favor indique el subtítulo.");
          alert(strMessage);
          Ember.$("#modalNoticia #subtitulo").focus();
          return false;
        }

        if (tags == null || tags.length == 0 || /^\s+$/.test(tags)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_tag.valor", "Por favor indique el tag.");
          alert(strMessage);
          Ember.$("#modalNoticia #tags").focus();
          return false;
        }

        if (tipo_noticia_k == null || tipo_noticia_k.length == 0 || /^\s+$/.test(tipo_noticia_k)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_tipo_noticia.valor", "Por favor indique el tipo de noticia.");
          alert(strMessage);
          Ember.$("#modalNoticia #tipo_noticia").focus();
          return false;
        }

        if (tipo_noticia_k == 3) {
          if (comunidad_k == null || comunidad_k.length == 0 || /^\s+$/.test(comunidad_k)) {
            strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_comunidad.valor", "Por favor indique la comunidad.");
            alert(strMessage);
            Ember.$("#modalNoticia #mostrar_en").focus();
            return false;
          }
        }

        if (this.nombreFoto == null || this.nombreFoto.length == 0 || /^\s+$/.test(this.nombreFoto)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_imagen.valor", "Por favor cargue la imagen.");
          alert(strMessage);
          return false;
        }

        Ember.$("#modalNoticia #noticia").jqte();
        var noticia = Ember.$('#noticia').val();

        if (noticia == null || noticia.length == 0 || /^\s+$/.test(noticia)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_noticia.valor", "Por favor indique el contenido de la noticia.");
          alert(strMessage);
          Ember.$("#modalNoticia #noticia").focus();
          Ember.$("#modalNoticia #noticia").jqte();
          return false;
        }

        Ember.$("#modalNoticia #agregarNoticia").css('display', 'none');
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/agregar',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: encodeURI('i={"titulo":"' + titulo + '","subtitulo":"' + subtitulo + '","tags":"' + tags + '","comunidad_k":"' + comunidad_k + '","imagen":"' + imagen + '","imagen_crop":"' + imagen_crop + '","imagen_crop_x":"' + imagen_crop_x + '","imagen_crop_y":"' + imagen_crop_y + '","imagen_crop_width":"' + imagen_crop_width + '","imagen_crop_height":"' + imagen_crop_height + '","cuerpo":"","tipo":"' + tipo_noticia_k + '","eliminadas":[]}&cuerpo=' + noticia.replace(/&/g, '%26') + '&llave_k=').replace('/[+]/g', '%20').replace('/[=]/g', '%3D').replace('/[:]/g', '%3A').replace('/[/]/', '%2F').replace('/[¿]/g', '%BF').replace('/[?]/g', '%3F'),
          success: function success(response) {
            var r = JSON.parse(response).data;
            console.log(r);
            Ember.$('#modalNoticia .close').click(); //document.location.reload();
          },
          error: function error(response) {
            var parsedResponse = JSON.parse(response.body[0].innerText);

            if (parsedResponse.error) {
              Ember.$('#modal-avisos .modal-header').text('Error');
              Ember.$('#modal-avisos .modal-body').text(parsedResponse.error);
              Ember.$('#modal-avisos').modal('show');
            } else {
              if (parsedResponse.path.indexOf('http') != 0) {
                parsedResponse.path = constant.BASEURL + parsedResponse.path;
              }

              this.set('imageUploaded', parsedResponse);
            }
          }
        });
      },
      uploadImage: function uploadImage(file) {//this.nombreFoto = file.name;
        // get(this, 'uploadPhoto').perform(file);
      },
      someActionAfteRender: function someActionAfteRender() {}
    }
  });

  _exports.default = _default;
});