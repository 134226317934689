define("comunidades-nafin/templates/lms/reportes/seguimiento-individual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6U/vfEEO",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-12 col-md-12\"],[10,\"id\",\"espacioIframe\"],[10,\"style\",\"height: 100vh;\"],[8],[0,\"\\n\"],[6,\"iframe\"],[10,\"id\",\"diviFrame\"],[10,\"style\",\"overflow:auto;width:100%;height:100%; background: white;\"],[10,\"frameborder\",\"0\"],[11,\"src\",[27,[[20,\"reporte\"]]]],[8],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\"\\n\"],[1,[20,\"sub-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/lms/reportes/seguimiento-individual.hbs"
    }
  });

  _exports.default = _default;
});