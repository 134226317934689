define("comunidades-nafin/templates/components/human-talent-components/charts/clustered-column-chart-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KvMyi7og",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"card\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"card-header\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"align\",\"left\"],[8],[0,\"Distribución\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"id\",\"collapseOne\"],[10,\"class\",\"collapse show\"],[10,\"aria-labelledby\",\"headingOne\"],[10,\"data-parent\",\"#accordionExample\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"card-body\"],[8],[0,\"\\n            \"],[6,\"div\"],[11,\"id\",[27,[[20,\"chartID\"]]]],[10,\"class\",\"grafica-colaboradores\"],[10,\"style\",\"width: 100%; height: 350px;\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/human-talent-components/charts/clustered-column-chart-horizontal.hbs"
    }
  });

  _exports.default = _default;
});