define("comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/template-evaluado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BXSNUFBz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"img\"],[11,\"src\",[22,[\"evaluadoRecord\",\"ruta_absoluta_fotografia\"]]],[10,\"class\",\"rounded-circle\"],[11,\"alt\",[22,[\"evaluadoRecord\",\"nombre\"]]],[8],[9],[0,\" \"],[2,\" style=\\\"height: 100%;\\\" \"],[0,\"\\n\"],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[13,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/template-evaluado.hbs"
    }
  });

  _exports.default = _default;
});