define("comunidades-nafin/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwRyW8gM",
    "block": "{\"symbols\":[],\"statements\":[[2,\"<div id=\\\"loading\\\">\\n\\t<div id=\\\"loader\\\">\\n\\t\\t<div id=\\\"shadow\\\"></div>\\n\\t\\t<div id=\\\"box\\\" class=\\\"box-theme\\\">\\n\\t\\t\\t<img src=\\\"https://acegif.com/wp-content/uploads/loading-9.gif\\\" alt=\\\"\\\">\\n\\t\\t</div>\\n\\t</div>\\t\\n</div>\"],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"\"],[10,\"id\",\"loading\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[10,\"align\",\"center\"],[10,\"style\",\"height:100vh\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-12 mx-auto my-auto\"],[8],[0,\"\\n                \"],[6,\"img\"],[10,\"class\",\"\"],[10,\"src\",\"img/loading-23.gif\"],[10,\"style\",\"width:250px\"],[10,\"alt\",\"\"],[8],[9],[6,\"br\"],[8],[9],[0,\"\\n            \"],[9],[0,\"            \\n        \"],[9],[0,\"\\n        \\n    \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});