define("comunidades-nafin/routes/lms/reportes/reporte_individual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var reporte = 'https://datastudio.google.com/embed/reporting/25ce3dca-e389-4956-95f6-31a39cf8227e/page/eiRwC/?params=%7B"df4":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%258021449"%7D';
      $('#espacioIframe').html('<iframe id="diviFrame" style="overflow:auto;width:100%;height:100%; background: white;" frameborder="0" ></iframe>');
      var iframe = document.getElementById('diviFrame');
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(reporte);
      iframe.contentWindow.document.close();
    }
  });

  _exports.default = _default;
});