define("comunidades-nafin/routes/human-talent/sandbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /*
     *  Para propsito de pruebas generamos las variables con los datos
     *  necesarios para ser enviados a cada uno de los componentes.
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('lista_elementos', [{
        'rating': null
      }, {
        'rating': null
      }, {
        'rating': null
      }, {
        'rating': null
      }]);
    },
    actions: {
      onClickRating: function onClickRating(indice_elemento, selected_value) {
        var lista_elementos = this.controller.get('lista_elementos');
        Ember.set(lista_elementos[indice_elemento], 'rating', selected_value);
        this.controller.set('lista_elementos', lista_elementos);
      }
    }
  });

  _exports.default = _default;
});