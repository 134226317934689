define("comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/start-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "el7/QhtL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row star-rating-container\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[1,[26,\"star-rating\",null,[[\"width\",\"height\",\"fillColor\",\"baseColor\",\"numStars\",\"useHalfStars\",\"rating\",\"onClick\"],[[22,[\"width\"]],[22,[\"height\"]],[22,[\"fillColor\"]],[22,[\"baseColor\"]],[22,[\"numStars\"]],[22,[\"useHalfStars\"]],[22,[\"rating\"]],[26,\"action\",[[21,0,[]],\"onClick\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 mt-2\"],[8],[0,\"\\n        \"],[1,[20,\"nivelSeleccionado\"],false],[0,\" \\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[13,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/competencias/evaluacion-usuario/aplicacion/start-rating.hbs"
    }
  });

  _exports.default = _default;
});