define("comunidades-nafin/components/human-talent-components/h-t-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metodo: '',
    clase: '',
    guid: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.guid != null) {
        this.set('guid', this.guid);
      } else {
        this.guid = this.generateGuid();
        this.set('guid', this.guid);
      }

      this.set('metodo', this.metodo);
      this.set('clase', this.clase);
    },
    generateGuid: function generateGuid() {
      return Ember.generateGuid();
    },
    actions: {}
  });

  _exports.default = _default;
});