define("comunidades-nafin/components/competencias/evaluacion-usuario/aplicacion/start-rating", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    width: "15%",
    height: "15%",
    fillColor: null,
    baseColor: '#B0B0B0',
    useHalfStars: true,
    rating: null,
    numStars: 0,
    onclickFunction: null,
    nivelesArray: [],
    nivelSeleccionado: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('width', this.width);
      this.set('height', this.height);
      this.set('fillcolor', this.fillColor);
      this.set('baseColor', this.baseColor);
      this.set('useHalfStars', this.useHalfStars);
      this.set('numStars', this.numStars);
      this.set('rating', this.rating);
      this.set('nivelesArray', this.nivelesArray);
      this.set('nivelSeleccionado', this.nivelSeleccionado);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.actualizarNivelSeleccionado();
      this.actualizarNivelSeleccionadoClima();
      this.hover_img();
    },

    /**
     *
     */
    actualizarNivelSeleccionado: function actualizarNivelSeleccionado() {
      var self = this;
      var nivelesArray = this.get('nivelesArray');
      nivelesArray.forEach(function (nivelObject) {
        if (nivelObject.nivel == self.get('rating')) {
          self.set('nivelSeleccionado', nivelObject.descripcion);
        }
      });
      $(".star-empty, .star-full").attr("height", "15%");
      $(".star-empty, .star-full").attr("width", "15%");
    },
    actualizarNivelSeleccionadoClima: function actualizarNivelSeleccionadoClima() {
      var self = this,
          rating = self.get('rating'),
          x = 0;

      for (x = 1; x <= rating; x++) {
        $(".imagen_" + x).addClass("clicked");
        $(".imagen_" + x).addClass("imagen_" + x + "_hover");
      }
    },
    hover_img: function hover_img() {
      var self = this;
      $(document).ready(function () {
        // Elemento 1
        $(".imagen_1").hover(function () {
          $(this).addClass("imagen_1_hover");
        }, function () {
          if (!$(this).hasClass('clicked')) {
            $(this).removeClass("imagen_1_hover");
          }
        }); // Elemento 2

        $(".imagen_2").hover(function () {
          $(this).addClass("imagen_2_hover");
          $(".imagen_1").addClass("imagen_1_hover");
        }, function () {
          self.remover_class_hover(2);
        }); // Elemento 3

        $(".imagen_3").hover(function () {
          $(this).addClass("imagen_3_hover");
          $(".imagen_2").addClass("imagen_2_hover");
          $(".imagen_1").addClass("imagen_1_hover");
        }, function () {
          self.remover_class_hover(3);
        }); // Elemento 4

        $(".imagen_4").hover(function () {
          $(this).addClass("imagen_4_hover");
          $(".imagen_3").addClass("imagen_3_hover");
          $(".imagen_2").addClass("imagen_2_hover");
          $(".imagen_1").addClass("imagen_1_hover");
        }, function () {
          self.remover_class_hover(4);
        }); // Elemento 5

        $(".imagen_5").hover(function () {
          $(this).addClass("imagen_5_hover");
          $(".imagen_4").addClass("imagen_4_hover");
          $(".imagen_3").addClass("imagen_3_hover");
          $(".imagen_2").addClass("imagen_2_hover");
          $(".imagen_1").addClass("imagen_1_hover");
        }, function () {
          self.remover_class_hover(5);
        });
      });
    },
    remover_class_hover: function remover_class_hover(rating) {
      var x = 1;

      for (x = 1; x <= rating; x++) {
        if (!$(".imagen_" + x).hasClass('clicked')) {
          $(".imagen_" + x).removeClass("imagen_" + x + "_hover");
        }
      }
    },
    nivel_seleccionado: function nivel_seleccionado(new_rating) {
      var x = 1;

      for (x = 1; x <= 5; x++) {
        $(".imagen_" + x).removeClass("clicked");
        $(".imagen_" + x).removeClass("imagen_" + x + "_hover");
      }

      for (x = 1; x <= new_rating; x++) {
        $(".imagen_" + x).addClass("clicked");
        $(".imagen_" + x).addClass("imagen_" + x + "_hover");
      }
    },

    /**
     *
     */
    actions: {
      onClick: function onClick(new_rating) {
        var self = this;
        var nivelesArray = this.get('nivelesArray');

        if (typeof this.onclickFunction == 'function') {
          this.onclickFunction(new_rating);
        }

        nivelesArray.forEach(function (nivelObject) {
          if (nivelObject.nivel == new_rating) {
            self.set('nivelSeleccionado', nivelObject.descripcion);
          }
        });
        $(".star-empty, .star-full").attr("height", "15%");
        $(".star-empty, .star-full").attr("width", "15%");
      },
      onClickClima: function onClickClima(new_rating) {
        var self = this;
        var nivelesArray = this.get('nivelesArray');

        if (typeof this.onclickFunction == 'function') {
          this.onclickFunction(new_rating);
        }

        nivelesArray.forEach(function (nivelObject) {
          if (nivelObject.nivel == new_rating) {
            self.set('nivelSeleccionado', nivelObject.descripcion);
          }
        });
        $(".star-empty, .star-full").attr("height", "15%");
        $(".star-empty, .star-full").attr("width", "15%"); // Si la app es Clima, mostramos el rating seleccionado

        this.nivel_seleccionado(new_rating);
      }
    }
  });

  _exports.default = _default;
});