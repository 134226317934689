define("comunidades-nafin/components/competencias/evaluacion-usuario/aplicacion/panel-retroalimentacion", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      get = _Ember.get,
      set = _Ember.set;

  var _default = Ember.Component.extend({
    /*
     * propiedades internas al objeto
     */
    self: void 0,
    updateRecord: null,
    evaluacion_k: null,

    /*
     * Propiedades requeridas a ser asignadas a nivel de controlller
     */
    isWorking: false,
    isFinished: false,
    hasError: false,
    command: 'create',
    errorMessage: '',
    didReceiveAttr: function didReceiveAttr() {
      this._super.apply(this, arguments);

      this.set('isWorking', this.isWorking);
      this.set('isFinished', this.isFinished);
      this.set('hasError', this.hasError);
      this.set('command', this.command);
      this.set('errorMessage', this.errorMessage);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
    },
    _showModal: function _showModal() {
      this.set('isVisibleCreateUpdateForm', true);

      if (this.get('command') == 'update') {
        this.set('isCommandUpdate', true);
      } else {
        this.set('isCommandUpdate ', false);
      }

      this.set('isWorking', false);
      this.set('isFinished', false);
      this.set('hasError', false);
      this.set('errorMessage', '');
      Ember.$('#button-save-changes').attr("disabled", "disabled");
    },
    _hideModal: function _hideModal() {
      this.set('command', this.command);
      this.set('isCommandUpdate', false);
      this.set('isWorking', false);
      this.set('isFinished', false);
      this.set('hasError', false);
      this.set('errorMessage', '');
      this.set('isVisibleCreateUpdateForm', false);
      Ember.$('#button-save-changes').attr("disabled", "disabled");
      Ember.$('#createUpdateFormWorkPlan').modal('hide');
    },
    _submit: function _submit(recordToUpdate, CI_controller, CI_method, success_callback_function) {
      /*
       * @todo: for now  can´t posible use recordToUpdate.toJSON(). The returned values are not updated (not correct).
       */
      var self = this;
      this.set('isWorking', true);
      /**
       * [YURAM] BEGIN
       *
       *  #Hack para recuperar datos del formulario.
       */

      if (Ember.$('#inputComentario').val() == '' || Ember.$('#inputComentario').val() == null) {
        this.set('isWorking', false);
        this.set('isFinished', false);
        this.set('hasError', true);
        this.set('errorMessage', 'Para continuar es necesario capturar una retroalimentacion.');
        return;
      }
      /**
       * [YURAM] END
       *
       */


      var nuevo_comentario = Ember.$('#inputComentario').val();
      var recordToUpdateObject = {
        e: this.evaluacion_k,
        datos: JSON.stringify([{
          "competencia_k": this.updateRecord.competencia_k,
          "data": [{
            "evaluado_k": this.updateRecord.evaluado_k,
            "calificacion": this.updateRecord.calificacion,
            "evidencia_producto": this.updateRecord.nivel_evidencia_producto,
            "comentario": nuevo_comentario
          }]
        }])
      };
      Ember.$.ajax({
        url: constant.APIURL + '/' + CI_controller + '/' + CI_method,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: recordToUpdateObject,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var response = JSON.parse(response_string);

          if (response.success) {
            self.set('isWorking', false);
            self.set('isFinished', true);
            self.set('hasError', false);
            console.dir(self.updateRecord);
            console.dir(nuevo_comentario);
            Ember.set(self.updateRecord, 'comentario', nuevo_comentario);

            if (success_callback_function) {
              success_callback_function(nuevo_comentario);
            }

            if (self.get('command') == 'update') {
              /*Hack para ocultar modal inmediatamente*/
              self._hideModal();

              if (self.onAfterUpdate) {
                self.onAfterUpdate(response.data);
              }
            } else {
              /*Hack para ocultar modal inmediatamente*/
              self._hideModal();

              if (self.onAfterCreate) {
                self.onAfterCreate(response.data);
              }
            }
          } else {
            if (response.msg || response.error) {
              /*
               * El servidor nos devuelve un error
               */
              self.set('isWorking', false);
              self.set('isFinished', false);
              self.set('hasError', true);
              self.set('errorMessage', response.msg ? response.msg : response.error);
            } else {
              self.set('isWorking', false);
              self.set('isFinished', true);
              self.set('hasError', true);
            }
          }
        },
        error: function error(_error) {
          /*
           * no se pudo eliminar el registro....
           */
          self.set('isWorking', false);
          self.set('isFinished', true);
          self.set('hasError', true);
        }
      });
    },
    actions: {
      showModal: function showModal() {
        this._showModal();
      },
      hideModal: function hideModal() {
        this._hideModal();
      },
      submit: function submit(storeRecord) {
        var record = storeRecord;
        /*StoreRecord para este formulario es null*/

        this._submit(record, 'e360/evaluacion360', 'guardar', function () {});
      }
    }
  });

  _exports.default = _default;
});