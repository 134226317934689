define("comunidades-nafin/components/human-talent-components/inputs/form-group/select", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    isWorking: false,
    value: '0',
    text: 'default',
    clase: 'clase',
    metodo: 'metodo',
    params: {},
    columnaValor: '',
    columnaEtiqueta: '',
    label: '',
    placeholder: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('isWorking', this.isWorking);
      this.set('value', this.value);
      this.set('text', this.text);
      this.set('clase', this.clase);
      this.set('metodo', this.metodo);
      this.set('params', this.params);
      this.set('columnaValor', this.columnaValor);
      this.set('columnaEtiqueta', this.columnaEtiqueta);
      this.set('label', this.label);
      this.set('placeholder', this.placeholder);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadData();
    },
    loadData: function loadData() {
      this._super.apply(this, arguments);

      var self = this;
      $.ajax({
        url: constant.APIURL + self.get('clase') + '/' + self.get('metodo'),
        type: "post",
        async: false,
        data: {
          limit: 1000,
          params: self.get('params')
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(responseObject) {
          if (responseObject.success == true) {
            var data_array = [];
            responseObject.data.forEach(function (recordData) {
              data_array.push({
                'value': recordData[self.get('columnaValor')],
                'text': recordData[self.get('columnaEtiqueta')]
              });
            });
            self.set('data', data_array);
            $('select#' + self.get('guid')).each(function () {
              $(this).change(function () {
                var _value = $('select#' + self.get('guid') + ' option:selected').val();

                var _text = $('select#' + self.get('guid') + ' option:selected').text();

                self.registrarRegistroSeleccionado({
                  "value": _value,
                  "text": _text
                });
              });
            });
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    registrarRegistroSeleccionado: function registrarRegistroSeleccionado(nuevoRegistro) {
      this.set('text', nuevoRegistro.text);
      this.set('value', nuevoRegistro.value);
    },
    actions: {}
  });

  _exports.default = _default;
});