define("comunidades-nafin/components/nom037/reportes/reports/estatus-de-capacitacion", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Estatus de capacitación',
    clase: 'nom037/capacitaciones',
    metodo: 'estatusdecapacitaciones',
    reporte: 'nom037/capacitaciones/exportestatusdecapacitaciones',
    periodo_k: null,
    headers: [{
      dataIndex: 'nombre_usuario',
      title: 'Usuario'
    }, {
      dataIndex: 'puesto',
      title: 'Puesto'
    }, {
      dataIndex: 'sucursal',
      title: 'Centro de trabajo'
    }, {
      dataIndex: 'departamento',
      title: 'Departamento'
    }, {
      dataIndex: 'curso',
      title: 'Curso'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', {
        'periodo_k': this.periodo_k
      });
    }
  });

  _exports.default = _default;
});